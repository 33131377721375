import { IAddress } from '@/types';

function convertAddressToString(address: IAddress | null | undefined): string {
  if (!address) {
    return '';
  }
  return [
    address?.buildingNumber,
    address?.street,
    address?.city,
    address?.stateOrProvince,
    address?.postalCode,
    address?.country,
  ]
    .filter(Boolean)
    .join(', ');
}

export default convertAddressToString;
