import React from 'react';
import { createContext, ReactNode, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchingLogout, setAuthTokens } from '@/store/auth/authSlices';

type AuthContextType = {
  accessToken: string | null;
  isAuthenticated: boolean;
  login: (token: string) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType>({
  accessToken: null,
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useDispatch();
  const [accessToken, setAccessToken] = useState<string | null>(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      dispatch(setAuthTokens(token));
    }
    return localStorage.getItem('accessToken') || null;
  });

  const isAuthenticated = !!accessToken;

  const login = (token: string) => {
    if (!token) {
      return;
    }
    setAccessToken(token);
    // Можно добавить сохранение токена в localStorage или sessionStorage
    localStorage.setItem('accessToken', token);
    dispatch(setAuthTokens(token));
  };

  const logout = () => {
    setAccessToken(null);
    // Удаление токена из localStorage
    localStorage.removeItem('accessToken');
    dispatch(fetchingLogout());
  };

  const value = {
    accessToken,
    isAuthenticated,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
