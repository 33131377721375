import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SigninDto, SignupDto } from '@/types';

interface AuthState {
  loginLoading: boolean;
  loginError: { [key: string]: string[] } | null;
  accessToken: string | null;
  propertyId?: number | null;
}

const initialState: AuthState = {
  loginLoading: false,
  loginError: null,
  accessToken: localStorage.getItem('accessToken') || null,
  propertyId: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthTokens(state, action: PayloadAction<string>) {
      state.accessToken = action.payload;
      localStorage.setItem('accessToken', action.payload);
    },
    setAuthPropertyId(state, action: PayloadAction<number | null | undefined>) {
      state.propertyId = action.payload;
    },
    clearAuthTokens(state) {
      state.accessToken = null;
      state.propertyId = null;
      localStorage.removeItem('accessToken');
    },
    fetchingLogout: (state) => {
      state.accessToken = null;
      state.propertyId = null;
      localStorage.removeItem('accessToken');
    },
    fetchingLogoutSuccess: (state) => {
      state.loginError = null;
      state.loginLoading = false;
    },
    fetchingLogoutError: (
      state,
      action: PayloadAction<{ [key: string]: string[] }>,
    ) => {
      state.loginError = action.payload;
      state.loginLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchingSignup: (state, _action: PayloadAction<SignupDto>) => {
      state.loginError = null;
      state.loginLoading = true;
    },
    fetchingSignupSuccess: (state) => {
      state.loginError = null;
      state.loginLoading = false;
    },
    fetchingSignupError: (
      state,
      action: PayloadAction<{ [key: string]: string[] }>,
    ) => {
      state.loginError = action.payload;
      state.loginLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchingSignin: (state, _action: PayloadAction<SigninDto>) => {
      state.loginError = null;
      state.loginLoading = true;
    },
    fetchingSigninSuccess: (state) => {
      state.loginError = null;
      state.loginLoading = false;
    },
    fetchingSigninError: (
      state,
      action: PayloadAction<{ [key: string]: string[] }>,
    ) => {
      state.loginError = action.payload;
      state.loginLoading = false;
    },
  },
});

export const {
  setAuthTokens,
  clearAuthTokens,
  fetchingSignup,
  fetchingSignupError,
  fetchingSignupSuccess,
  fetchingSignin,
  fetchingSigninSuccess,
  fetchingSigninError,
  fetchingLogout,
  fetchingLogoutSuccess,
  fetchingLogoutError,
  setAuthPropertyId,
} = authSlice.actions;

export const loginLoadingSelector = (state: PropSavyRootState) =>
  state.auth.loginLoading;
export const loginErrorSelector = (state: PropSavyRootState) =>
  state.auth.loginError;
export const accessTokenSelector = (state: PropSavyRootState) =>
  state.auth.accessToken;
export const isAuthenticatedSelector = (state: PropSavyRootState) =>
  !!state.auth.accessToken;
export const propertyIdSelector = (state: PropSavyRootState) =>
  state.auth.propertyId;
export default authSlice.reducer;
