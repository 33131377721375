import React, { FC } from 'react';
import './UnitsListCard.scss';
import Card from '@/components/Card';
import Button from '@/components/Button';
import PropertyUnitsTable from '@/components/PropertyUnitsTable';
import { useNavigate } from 'react-router-dom';
import { IProperty, IUnit } from '@/types';

const baseClass = 'UnitsListCard';

interface UnitsListCardProps {
  className?: string;
  property: IProperty | null | undefined;
  newUnits?: IUnit[];
}

const UnitsListCard: FC<UnitsListCardProps> = ({
  property,
  className = '',
  newUnits = [],
}) => {
  const navigate = useNavigate();
  return (
    <Card className={`${baseClass} ${className}`}>
      <Card.Header className={`${baseClass}-header`}>
        <Card.Title className={`${baseClass}-header-title`}>
          <span className={`${baseClass}-header-title-text`}>
            List of units
          </span>
          <Button
            className={`${baseClass}-header-title-btn`}
            onClick={() => {
              navigate(`/properties/${property?.id}/units/add`);
            }}
          >
            Add unit
          </Button>
        </Card.Title>
      </Card.Header>
      <Card.Body className={`${baseClass}-body`}>
        <PropertyUnitsTable property={property} newUnits={newUnits} />
      </Card.Body>
    </Card>
  );
};

export default UnitsListCard;
