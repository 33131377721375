import React from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '@/api';
import './UnableToConnect.scss';

const baseClass = 'UnableToConnect';

const UnableToConnect = () => {
  const navigate = useNavigate();
  const [timerId, setTimerId] = React.useState<NodeJS.Timer | null>(null);

  // useEffect(() => {
  //   console.log('UnableToConnect useEffect', API_URL);
  //   const timer = setInterval(() => {
  //     axios
  //       .get(`${API_URL}/health`)
  //       .then((res) => {
  //         if (res && res.status === 200) {
  //           if (res.data.status === 'alive') {
  //             navigate('/');
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         console.log('UnableToConnect useEffect', err);
  //       });
  //   }, 10000);
  //
  //   setTimerId(timer);
  //
  //   return () => {
  //     if (timerId) {
  //       clearTimeout(timerId);
  //     }
  //   };
  // }, [timerId]);

  console.debug('UnableToConnect useEffect', API_URL);

  return (
    <div className={baseClass}>
      <div>
        <i className="fa-solid fa-laptop-code"></i>
      </div>
      <p className={`${baseClass}--message`}>
        The server is temporarily unavailable. Please try again later.
      </p>
    </div>
  );
};

export default UnableToConnect;
