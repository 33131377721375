import { call, put, takeLatest } from 'redux-saga/effects';
import { type PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import {
  fetchingSigninError,
  fetchingSignup,
  fetchingSignupSuccess,
  setAuthTokens,
} from '../authSlices';
import { SignupDto } from '@/types';
import { signupRequest } from '@/api';
import handleRequestErrors from '@/utils/handleRequestErrors';

function* signupSaga(action: PayloadAction<SignupDto>) {
  const request = async (data: SignupDto) => signupRequest(data);
  try {
    const {
      data: { accessToken },
    }: AxiosResponse<{ refreshToken: string; accessToken: string }> =
      yield call(request, action.payload);
    // Сохранение токенов в Redux
    yield put(setAuthTokens(accessToken));
    yield put(fetchingSignupSuccess());
  } catch (error) {
    yield put(handleRequestErrors(error, fetchingSigninError));
    // if (error.response) {
    //   // Ошибка от сервера
    //   yield put(
    //     fetchingSignupError(
    //       error.response?.data?.message || 'Registration failed',
    //     ),
    //   );
    // } else {
    //   // Ошибка запроса
    //   yield put(fetchingSignupError('An error occurred'));
    // }
  }
}

function* watchSignupSaga() {
  yield takeLatest(fetchingSignup, signupSaga);
}

export default watchSignupSaga;
