import React, { useEffect } from 'react';
import Card from '@/components/Card';
import { Form, Formik, FormikProps } from 'formik';
import Input from '@/components/Input';
import { OpenStreetMapProvider, SearchResult } from 'leaflet-geosearch';
import MapComponent from '@/components/MapComponent';
import './AddProperty.scss';
import Button from '@/components/Button';
import { createPropertyRequest } from '@/api';
import { IProperty } from '@/types';
import getAxiosErrors from '@/utils/getAxiosErrors';
import { useNavigate } from 'react-router-dom';
import convertAddressToStringWithoutZip from '@/utils/convertAddressToStringWithoutZip';

const provider = new OpenStreetMapProvider();

const baseClass = 'AddProperty';

// interface IAddress {
//   country: string;
//   stateOrProvince?: string | null;
//   city?: string | null;
//   postalCode?: string | null;
//   street?: string | null;
//   buildingNumber?: string | null;
//   latitude: string | null;
//   longitude: string | null;
//   apartments?: IUnit[];
// }

// interface IProperty {
//   name: string;
//   address: IAddress;
// }

interface FormikValues {
  name: string;
  country: string;
  stateOrProvince: string | null;
  city: string | null;
  postalCode: string | null;
  buildingNumber: string | null;
  street: string | null;
  buildingType: 'detachedHouse' | 'apartmentBuilding'; // isDetachedHouse: boolean;
}

const initialValues: FormikValues = {
  name: '',
  country: '',
  stateOrProvince: null,
  city: null,
  postalCode: null,
  buildingNumber: null,
  street: null,
  buildingType: 'apartmentBuilding',
};

const AddProperty = () => {
  const navigate = useNavigate();
  const [timer, setTimer] = React.useState<NodeJS.Timer | null>(null);

  const [countryState, setCountryState] = React.useState<string>('');
  const [stateState, setStateState] = React.useState<string>('');
  const [cityState, setCityState] = React.useState<string>('');
  const [postalCodeState, setPostalCodeState] = React.useState<string>('');
  const [buildingNumberState, setBuildingNumberState] =
    React.useState<string>('');
  const [streetState, setStreetState] = React.useState<string>('');
  const [isMapSearch, setIsMapSearch] = React.useState<boolean>(false);

  const [searchResults, setSearchResults] = React.useState<SearchResult[]>([]);

  useEffect(() => {
    setIsMapSearch(true);
    const query = convertAddressToStringWithoutZip({
      buildingNumber: buildingNumberState,
      street: streetState,
      city: cityState,
      stateOrProvince: stateState,
      country: countryState,
    });
    if (timer) {
      clearTimeout(timer);
    }

    if (query) {
      setTimer(
        setTimeout(() => {
          provider
            .search({ query: query.trim() || '' })
            .then(setSearchResults)
            .catch(console.error)
            .finally(() => {
              setIsMapSearch(false);
            });
        }, 1000),
      );
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line
  }, [
    countryState,
    stateState,
    cityState,
    postalCodeState,
    buildingNumberState,
    streetState,
  ]);
  return (
    <Card className={baseClass}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm, setErrors, setSubmitting }) => {
          const geoData = searchResults.filter((geoDataItem) => {
            return ['building', 'house'].some(
              (item) =>
                item === geoDataItem.raw?.addresstype ||
                item === geoDataItem.raw?.type,
            );
          });
          console.debug(geoData);
          const data: IProperty = {
            name: values.name,
            address: {
              country: values.country,
              stateOrProvince: values.stateOrProvince,
              city: values.city,
              postalCode: values.postalCode,
              buildingNumber: values.buildingNumber,
              street: values.street,
              latitude: geoData[0]?.y || null,
              longitude: geoData[0]?.x || null,
            },
          };
          setSubmitting(true);
          createPropertyRequest(data)
            .then((res) => {
              console.debug(res.data); // TODO if !res || !res.status|| !res.data
              navigate(`/properties`);
            })
            .catch((err) => {
              setErrors(getAxiosErrors(err));
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
        onReset={() => {
          setSearchResults([]);
        }}
      >
        {({
          values,
          errors,
          handleBlur,
          touched,
          setFieldValue,
          handleChange,
          isSubmitting,
          dirty,
        }: FormikProps<FormikValues>) => {
          const handleFieldChange =
            (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = event.target;
              setFieldValue(field, value);
              switch (field) {
                case 'country':
                  setCountryState(value);
                  break;
                case 'stateOrProvince':
                  setStateState(value);
                  break;
                case 'city':
                  setCityState(value);
                  break;
                case 'postalCode':
                  setPostalCodeState(value);
                  break;
                case 'buildingNumber':
                  setBuildingNumberState(value);
                  break;
                case 'street':
                  setStreetState(value);
                  break;
              }

              // Выполняем поиск адреса после изменения значений
            };

          return (
            <Form className={`${baseClass}-form`}>
              <Card.Title>Add Property</Card.Title>
              <Card.Body>
                <div className={`${baseClass}-form-group`}>
                  <Input
                    className={`${baseClass}-form-group-item`}
                    // labelClassName={`${baseClass}--row-label`}
                    label={'Name*'}
                    floatLabel={true}
                    name={'name'}
                    onChange={handleFieldChange('name')}
                    onBlur={handleBlur}
                    value={values.name}
                    errors={errors.name && touched.name ? errors.name : ''}
                    // errors={
                    //   getIn(errors, 'name') && getIn(touched, 'name')
                    //     ? getIn(errors, 'name')
                    //     : ''
                    // }
                  />
                </div>
                <div className={`${baseClass}-form-group`}>
                  <Input
                    className={`${baseClass}-form-group-item`}
                    // labelClassName={`${baseClass}--row-label`}
                    label={'Country*'}
                    floatLabel={true}
                    name={'country'}
                    onChange={handleFieldChange('country')}
                    onBlur={handleBlur}
                    value={values.country}
                    errors={
                      errors.country && touched.country ? errors.country : ''
                    }
                  />
                  <Input
                    className={`${baseClass}-form-group-item`}
                    // labelClassName={`${baseClass}--row-label`}
                    label={'Postal code'}
                    floatLabel={true}
                    name={'postalCode'}
                    onChange={handleFieldChange('postalCode')}
                    onBlur={handleBlur}
                    value={values.postalCode}
                    errors={
                      errors.postalCode && touched.postalCode
                        ? errors.postalCode
                        : ''
                    }
                  />
                </div>
                <div className={`${baseClass}-form-group`}>
                  <Input
                    className={`${baseClass}-form-group-item`}
                    label={
                      values.country.trim().toUpperCase() !== 'CANADA'
                        ? 'State'
                        : 'Province'
                    }
                    floatLabel
                    name={'stateOrProvince'}
                    onChange={handleFieldChange('stateOrProvince')}
                    onBlur={handleBlur}
                    value={values.stateOrProvince}
                    errors={
                      errors.stateOrProvince && touched.stateOrProvince
                        ? errors.stateOrProvince
                        : ''
                    }
                  />
                  <Input
                    className={`${baseClass}-form-group-item`}
                    floatLabel
                    label={'City'}
                    name={'city'}
                    onChange={handleFieldChange('city')}
                    onBlur={handleBlur}
                    value={values.city}
                    errors={errors.city && touched.city ? errors.city : ''}
                  />
                </div>
                <div className={`${baseClass}-form-group`}>
                  <Input
                    className={`${baseClass}-form-group-item`}
                    label={'Street'}
                    floatLabel
                    name={'street'}
                    onChange={handleFieldChange('street')}
                    onBlur={handleBlur}
                    value={values.street}
                    errors={
                      errors.street && touched.street ? errors.street : ''
                    }
                  />
                  <Input
                    className={`${baseClass}-form-group-item`}
                    label={'Building number'}
                    floatLabel
                    name={'buildingNumber'}
                    onChange={handleFieldChange('buildingNumber')}
                    onBlur={handleBlur}
                    value={values.buildingNumber}
                    errors={
                      errors.buildingNumber && touched.buildingNumber
                        ? errors.buildingNumber
                        : ''
                    }
                  />
                </div>
                {/*<div className={`${baseClass}-form-group`}>*/}
                {/*  <Select*/}
                {/*    className={`${baseClass}-form-group-item`}*/}
                {/*    label={'Building type'}*/}
                {/*    floatLabel*/}
                {/*    name={'buildingType'}*/}
                {/*    onChange={handleChange}*/}
                {/*    onBlur={handleBlur}*/}
                {/*    value={values.buildingType}*/}
                {/*    errors={*/}
                {/*      errors.buildingType && touched.buildingType*/}
                {/*        ? errors.buildingType*/}
                {/*        : ''*/}
                {/*    }*/}
                {/*    options={[*/}
                {/*      {*/}
                {/*        label: 'Apartment building',*/}
                {/*        value: 'apartmentBuilding',*/}
                {/*      },*/}
                {/*      { label: 'Detached house', value: 'detachedHouse' },*/}
                {/*    ]}*/}
                {/*  />*/}
                {/*</div>*/}
                <div className={`${baseClass}-form-btns`}>
                  <Button
                    type="submit"
                    isDisabled={isSubmitting || isMapSearch}
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                  <Button type="reset" theme={'secondary'} isDisabled={!dirty}>
                    Reset
                  </Button>
                </div>
              </Card.Body>
            </Form>
          );
        }}
      </Formik>
      <MapComponent geoData={searchResults} />
    </Card>
  );
};

export default AddProperty;
