import {
  updatingProperty,
  updatingPropertyError,
  updatingPropertySuccess,
} from '@/store/property/propertySlices';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { updatePropertyRequest } from '@/api';
import { UpdatePropertyRequest } from '@/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

function* updatePropertySaga(action: PayloadAction<UpdatePropertyRequest>) {
  const request = async (params: UpdatePropertyRequest) =>
    updatePropertyRequest(params);
  try {
    const response: AxiosResponse = yield call(request, action.payload);
    yield put(updatingPropertySuccess(response.data));
  } catch (error) {
    yield all([
      // put(setIsPropertyCreated(false)),
      put(updatingPropertyError(error)),
    ]);
  }
}

function* watchUpdatePropertySaga() {
  yield takeLatest(updatingProperty, updatePropertySaga);
}

export default watchUpdatePropertySaga;
