import React from 'react';
import logo from '../../img/propsavvy-logo-with-text.png';
import { Link } from 'react-router-dom';
import './ForgotPasswordStyles.scss';

const baseClass = 'forgotPasswordPage';

const ForgotPassword = () => {
  return (
    <div className={baseClass}>
      <div className={`${baseClass}-view`}>
        <div className={`${baseClass}-view-logo`}>
          <img src={logo} alt="logo" />
          <p className={`${baseClass}-view-logo-desc`}>
            Please enter your user information.
          </p>
        </div>
        <form className={`${baseClass}-view-form`}>
          <div className={`${baseClass}-view-form-group`}>
            <label
              className={`${baseClass}-view-form-group-label`}
              htmlFor="username"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className={`${baseClass}-view-form-group-input`}
            />
          </div>
          <div className={`${baseClass}-view-form-group-submit`}>
            <button
              type="submit"
              className={`${baseClass}-view-form-group-submit-btn`}
            >
              Login
            </button>
          </div>
          <div className={`${baseClass}-view-form-group-bottom`}>
            <Link
              to={'/register'}
              className={`${baseClass}-view-form-group-bottom-create`}
            >
              Create an account
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
