import { IAddress } from '@/types';

function convertAddressToStringWithoutZip(address: IAddress): string {
  if (!address) {
    return '';
  }
  return [
    address?.buildingNumber,
    address?.street,
    address?.city,
    address?.stateOrProvince,
    address?.country,
  ]
    .filter(Boolean)
    .join(', ');
}

export default convertAddressToStringWithoutZip;
