import { call, put, takeLatest } from 'redux-saga/effects';
import { getPropertiesRequest } from '@/api';
import {
  fetchingProperties,
  fetchingPropertiesError,
  fetchingPropertiesSuccess,
} from '@/store/properties/propertiesSlices';
import { AxiosResponse } from 'axios';
import { FetchAllPropertiesResponse } from '@/types';

function* getPropertiesListSaga() {
  const request = async () => getPropertiesRequest();
  try {
    const response: AxiosResponse<FetchAllPropertiesResponse> =
      yield call(request);
    yield put(fetchingPropertiesSuccess(response.data));
  } catch (error) {
    yield put(fetchingPropertiesError(error));
  }
}

function* watchGetPropertiesListSaga() {
  yield takeLatest(fetchingProperties, getPropertiesListSaga);
}

export default watchGetPropertiesListSaga;
