import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Card from '@/components/Card';
import Button from '@/components/Button';
import Input from '@/components/Input';
import { FieldArray, Form, Formik, getIn } from 'formik';
import { AddPropertyValues, IProperty, IUnit } from '@/types';
import * as Yup from 'yup';
import {
  createUnitsNumbersRequest,
  deletePropertyRequest,
  getPropertyRequest,
  updatePropertyRequest,
} from '@/api';
import { profileSelector } from '@/store/profile/profileSlices';
import FileUploader from '@/components/FileUploader';
import PropertyOverviewCard from '@/components/PropertyOverviewCard';
import UnitsListCard from '@/components/UnitsListCard';
import Dialog from '@/components/Dialog';
import './EditProperty.scss';
import { OpenStreetMapProvider, SearchResult } from 'leaflet-geosearch';
import convertAddressToStringWithoutZip from '@/utils/convertAddressToStringWithoutZip';

const provider = new OpenStreetMapProvider();

const baseClass = 'EditProperty';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  address: Yup.object().shape({
    country: Yup.string().required('Country is required'),
    stateOrProvince: Yup.string().trim().nullable(),
    state: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null
    city: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    street: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    buildingNumber: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    postalCode: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null
    phone: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null,
    latitude: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null,
    longitude: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null,
  }),
});

const unitsValidationSchema = Yup.object().shape({
  items: Yup.array().of(
    Yup.object().shape({
      number: Yup.string().required('Required').trim(),
    }),
  ),
});

const initialValues: AddPropertyValues = {
  name: '',
  address: {
    country: '',
    stateOrProvince: null,
    city: null,
    street: null,
    buildingNumber: null,
    postalCode: null,
    phone: null,
    latitude: null,
    longitude: null,
  },
};

const EditProperty = () => {
  const { propertyId } = useParams<{ propertyId: string }>();
  const navigate = useNavigate();
  const [property, setProperty] = useState<IProperty | null>(null);
  const profile = useSelector(profileSelector);
  const [isPropertyUpdated, setIsPropertyUpdated] = useState(false);
  const [isPropertyDeleted, setIsPropertyDeleted] = useState(false);
  const [isPropertyLoading, setIsPropertyLoading] = useState(true);
  const numericId = Number(propertyId);
  const isNumeric = !isNaN(numericId);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [newUnits, setNewUnits] = useState<IUnit[]>([]);

  const [timer, setTimer] = React.useState<NodeJS.Timer | null>(null);

  const [countryState, setCountryState] = React.useState<string>('');
  const [stateState, setStateState] = React.useState<string>('');
  const [cityState, setCityState] = React.useState<string>('');
  const [postalCodeState, setPostalCodeState] = React.useState<string>('');
  const [buildingNumberState, setBuildingNumberState] =
    React.useState<string>('');
  const [streetState, setStreetState] = React.useState<string>('');
  const [isMapSearch, setIsMapSearch] = React.useState<boolean>(false);

  const [searchResults, setSearchResults] = React.useState<SearchResult[]>([]);

  useEffect(() => {
    setIsPropertyLoading(true);
    getPropertyRequest(numericId)
      .then((res) => {
        // TODO: if !res || !res.status|| !res.data
        setProperty(res.data);
        setCountryState(res.data.address.country || '');
        setStateState(res.data.address.stateOrProvince || '');
        setCityState(res.data.address.city || '');
        setPostalCodeState(res.data.address.postalCode || '');
        setBuildingNumberState(res.data.address.buildingNumber || '');
        setStreetState(res.data.address.street || '');
      })
      .catch((err) => {})
      .finally(() => {
        setIsPropertyLoading(false);
      });
  }, [numericId]);

  useEffect(() => {
    setIsMapSearch(true);
    const query = convertAddressToStringWithoutZip({
      buildingNumber: buildingNumberState,
      street: streetState,
      city: cityState,
      stateOrProvince: stateState,
      country: countryState,
    });
    if (timer) {
      clearTimeout(timer);
    }

    if (query) {
      setTimer(
        setTimeout(() => {
          provider
            .search({ query: query.trim() || '' })
            .then((res) => {
              setSearchResults(res);
              if (res && res.length > 0) {
                const geoData = res.filter((geoDataItem) => {
                  return ['building', 'house'].some(
                    (item) =>
                      item === geoDataItem.raw?.addresstype ||
                      item === geoDataItem.raw?.type,
                  );
                });
                if (geoData.length > 0) {
                  setProperty((prev) => {
                    if (prev) {
                      return {
                        ...prev,
                        address: {
                          ...prev.address,
                          country: countryState,
                          stateOrProvince: stateState,
                          city: cityState,
                          postalCode: postalCodeState,
                          buildingNumber: buildingNumberState,
                          street: streetState,
                          latitude: geoData[0]?.y || null,
                          longitude: geoData[0]?.x || null,
                        },
                      };
                    }
                    return prev;
                  });
                }
              } else {
                setProperty((prev) => {
                  if (prev) {
                    return {
                      ...prev,
                      address: {
                        ...prev.address,
                        country: countryState,
                        stateOrProvince: stateState,
                        city: cityState,
                        postalCode: postalCodeState,
                        buildingNumber: buildingNumberState,
                        street: streetState,
                        latitude: null,
                        longitude: null,
                      },
                    };
                  }
                  return prev;
                });
              }
            })
            .catch(console.error)
            .finally(() => {
              setIsMapSearch(false);
            });
        }, 1000),
      );
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line
  }, [
    countryState,
    stateState,
    cityState,
    postalCodeState,
    buildingNumberState,
    streetState,
  ]);

  // const copyToClipboard = useCallback((text: string) => {
  //   navigator.clipboard.writeText(text).then(
  //     () => {
  //       console.log('Copied to clipboard');
  //     },
  //     (err) => {
  //       console.error('Failed to copy: ', err);
  //     },
  //   );
  // }, []);

  useEffect(() => {
    if (property?.owner && profile?.id) {
      if (property?.owner?.id !== profile?.id) {
        navigate('..', { relative: 'path' });
      }
    }
  }, [navigate, property, profile]);

  useEffect(() => {
    if (isPropertyUpdated) {
      navigate('..', { relative: 'path' });
    }
  }, [isPropertyUpdated, navigate]);

  useEffect(() => {
    if (isPropertyDeleted) {
      navigate('/properties');
    }
  }, [isPropertyDeleted, navigate]);

  const deleteProperty = useCallback(async () => {
    setDeleting(true);
    await deletePropertyRequest(numericId)
      .then((res) => {
        console.debug('deleteProperty', res);
        setIsPropertyDeleted(true);
      })
      .catch((err) => {
        console.debug('DEl prop err: ', err);
      })
      .finally(() => {
        setDeleting(false);
      });
  }, [numericId]);

  if (!isNumeric || (!isPropertyLoading && !property)) {
    return (
      <Card className={baseClass}>
        <h4>Invalid property ID</h4>
        <Button onClick={() => navigate('/properties')}>
          Go back to properties
        </Button>
      </Card>
    );
  }
  return (
    <div className={baseClass}>
      <Card.Header className={`${baseClass}--header`}>
        <span className={`${baseClass}--header-text`}>
          <span className={`${baseClass}--header-text-prop`}>
            Edit property:
          </span>{' '}
          {property?.name}
        </span>
        <Button
          className={`${baseClass}--header-btn`}
          theme={'secondary'}
          onClick={() => {
            navigate(`/properties`);
          }}
        >
          Back to properties
        </Button>
      </Card.Header>
      <PropertyOverviewCard
        property={property}
        navButton={
          <Button
            className={`${baseClass}--overview-btn`}
            theme={'blue'}
            onClick={() => {
              navigate('/properties/' + property?.id);
            }}
          >
            <span className={`${baseClass}--overview-btn-content`}>
              <i className="fa-solid fa-circle-info"></i>
              Property information
            </span>
          </Button>
        }
      />
      <Card className={`${baseClass}--information`}>
        <Card.Header className={`${baseClass}--information-header`}>
          <Card.Title className={`${baseClass}--information-title`}>
            Property information
          </Card.Title>
        </Card.Header>
        <Card.Body className={`${baseClass}--information-body`}>
          <Card.Container className={`${baseClass}--information-container`}>
            <Formik
              enableReinitialize={true}
              validationSchema={validationSchema}
              initialValues={property || initialValues}
              onSubmit={(values) => {
                const geoData = searchResults.filter((geoDataItem) => {
                  return ['building', 'house'].some(
                    (item) =>
                      item === geoDataItem.raw?.addresstype ||
                      item === geoDataItem.raw?.type,
                  );
                });

                const data: AddPropertyValues = {
                  ...values,
                  address: {
                    ...values.address,
                    country: countryState,
                    stateOrProvince: stateState,
                    city: cityState,
                    postalCode: postalCodeState,
                    buildingNumber: buildingNumberState,
                    street: streetState,
                    latitude: geoData[0]?.y || null,
                    longitude: geoData[0]?.x || null,
                  },
                };
                console.debug('values', values);
                setIsPropertyLoading(true);
                updatePropertyRequest({ id: numericId, data })
                  .then((res) => {
                    console.debug('updatePropertyRequest', res);
                    setIsPropertyUpdated(true);
                  })
                  .catch((err) => {
                    console.debug('updatePropertyRequest error:', err);
                  })
                  .finally(() => {
                    setIsPropertyLoading(false);
                  });
              }}
            >
              {({
                values,
                errors,
                handleBlur,
                handleChange,
                touched,
                isSubmitting,
                setFieldValue,
              }) => {
                const handleFieldChange =
                  (field: string) =>
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;
                    setFieldValue(field, value);
                    switch (field) {
                      case 'address.country':
                        setCountryState(value);
                        break;
                      case 'address.stateOrProvince':
                        setStateState(value);
                        break;
                      case 'address.city':
                        setCityState(value);
                        break;
                      case 'address.postalCode':
                        setPostalCodeState(value);
                        break;
                      case 'address.buildingNumber':
                        setBuildingNumberState(value);
                        break;
                      case 'address.street':
                        setStreetState(value);
                        break;
                    }

                    // Выполняем поиск адреса после изменения значений
                  };
                return (
                  <Form className={`${baseClass}--information-form`}>
                    <div
                      className={`${baseClass}--information-form-item ${baseClass}--information-form-label`}
                    >
                      Property name
                    </div>
                    <Input
                      className={`${baseClass}--information-form-item ${baseClass}--information-form-input`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={'Property name'}
                      value={values.name}
                      name={'name'}
                      errors={errors.name && touched.name ? errors.name : ''}
                    />
                    <div
                      className={`${baseClass}--information-form-item ${baseClass}--information-form-label`}
                    >
                      Country
                    </div>
                    <Input
                      className={`${baseClass}--information-form-item ${baseClass}--information-form-input`}
                      onChange={handleFieldChange('address.country')}
                      onBlur={handleBlur}
                      placeholder={'Country'}
                      value={values.address.country}
                      name={'address.country'}
                      errors={
                        errors.address?.country && touched.address?.country
                          ? errors.address?.country
                          : ''
                      }
                    />
                    {/*<Select*/}
                    {/*  value={values.address.country}*/}
                    {/*  onBlur={handleBlur}*/}
                    {/*  onChange={handleChange}*/}
                    {/*  options={[*/}
                    {/*    { label: 'Canada', value: 'Canada' },*/}
                    {/*    { label: 'USA', value: 'USA' },*/}
                    {/*    { label: 'Ukraine', value: 'Ukraine' },*/}
                    {/*  ]}*/}
                    {/*  name={'address.country'}*/}
                    {/*  errors={*/}
                    {/*    errors.address?.country && touched.address?.country*/}
                    {/*      ? errors.address?.country*/}
                    {/*      : ''*/}
                    {/*  }*/}
                    {/*/>*/}
                    <div
                      className={`${baseClass}--information-form-item ${baseClass}--information-form-label`}
                    >
                      Province
                    </div>
                    <Input
                      className={`${baseClass}--information-form-item ${baseClass}--information-form-input`}
                      onChange={handleFieldChange('address.stateOrProvince')}
                      onBlur={handleBlur}
                      placeholder={'Province'}
                      value={values.address.stateOrProvince}
                      name={'address.stateOrProvince'}
                      errors={
                        errors.address?.stateOrProvince &&
                        touched.address?.stateOrProvince
                          ? errors.address?.stateOrProvince
                          : ''
                      }
                    />
                    <div
                      className={`${baseClass}--information-form-item ${baseClass}--information-form-label`}
                    >
                      City
                    </div>
                    <Input
                      className={`${baseClass}--information-form-item ${baseClass}--information-form-input`}
                      onChange={handleFieldChange('address.city')}
                      onBlur={handleBlur}
                      placeholder={'City'}
                      value={values.address.city}
                      name={'address.city'}
                      errors={
                        errors.address?.city && touched.address?.city
                          ? errors.address?.city
                          : ''
                      }
                    />
                    <div
                      className={`${baseClass}--information-form-item ${baseClass}--information-form-label`}
                    >
                      Address
                    </div>
                    <div
                      className={`${baseClass}--information-form-item ${baseClass}--information-form-address`}
                    >
                      <Input
                        className={`${baseClass}--information-form-address-input`}
                        onChange={handleFieldChange('address.buildingNumber')}
                        onBlur={handleBlur}
                        placeholder={'No.'}
                        value={values.address.buildingNumber}
                        name={'address.buildingNumber'}
                        errors={
                          errors.address?.buildingNumber &&
                          touched.address?.buildingNumber
                            ? errors.address?.buildingNumber
                            : ''
                        }
                      />
                      <Input
                        className={`${baseClass}--information-form-address-input`}
                        onChange={handleFieldChange('address.street')}
                        onBlur={handleBlur}
                        placeholder={'Street'}
                        value={values.address.street}
                        name={'address.street'}
                        errors={
                          errors.address?.street && touched.address?.street
                            ? errors.address?.street
                            : ''
                        }
                      />
                    </div>
                    <div
                      className={`${baseClass}--information-form-item ${baseClass}--information-form-label`}
                    >
                      Postal code
                    </div>
                    <Input
                      className={`${baseClass}--information-form-item ${baseClass}--information-form-input`}
                      onChange={handleFieldChange('address.postalCode')}
                      onBlur={handleBlur}
                      placeholder={'Postal code'}
                      value={values.address.postalCode}
                      name={'address.postalCode'}
                      errors={
                        errors.address?.postalCode &&
                        touched.address?.postalCode
                          ? errors.address?.postalCode
                          : ''
                      }
                    />
                    <div className={`${baseClass}--information-form-btns`}>
                      <Button
                        className={`${baseClass}--information-form-btn`}
                        theme={'primary'}
                        type="submit"
                        isLoading={
                          isPropertyLoading || isSubmitting || isMapSearch
                        }
                      >
                        Save
                      </Button>
                      <Button
                        className={`${baseClass}--information-form-btn`}
                        theme={'secondary'}
                        type={'reset'}
                        // isLoading={isPropertyLoading || isSubmitting}
                      >
                        Clear
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Card.Container>
        </Card.Body>
      </Card>
      <UnitsListCard property={property} newUnits={newUnits} />
      <Card className={`${baseClass}--dropper`}>
        <Card.Header className={`${baseClass}--dropper-header`}>
          <Card.Title className={`${baseClass}--dropper-title`}>
            Add units
          </Card.Title>
        </Card.Header>
        <Card.Body className={`${baseClass}--dropper-body`}>
          <Card.Container className={`${baseClass}--dropper-container`}>
            <FileUploader
              onFilesSelect={(f) => {
                console.debug('file', f);
              }}
              acceptFiles={['.xls', '.csv', '.xlsx', '.jpg', '.jpeg', '.png']}
              files={[]}
              filesLimit={1}
            />
          </Card.Container>
        </Card.Body>
      </Card>
      <Card className={`${baseClass}--addUnit`}>
        <Card.Header className={`${baseClass}--addUnit-header`}>
          <Card.Title className={`${baseClass}--addUnit-title`}>
            Add unit
          </Card.Title>
          <Card.TitleDescription className={`${baseClass}--addUnit-desc`}>
            To add multiple units, click ‘+’ button
          </Card.TitleDescription>
        </Card.Header>
        <Card.Body className={`${baseClass}--addUnit-body`}>
          <Card.Container className={`${baseClass}--addUnit-container`}>
            <Formik
              initialValues={{ items: [{ number: '' }] }} // Начальное значение с одним полем
              validationSchema={unitsValidationSchema}
              onSubmit={(values, { resetForm }) => {
                const { items } = values;
                const units = items.map((item) => ({
                  number: item.number.trim(),
                }));
                createUnitsNumbersRequest(numericId, units)
                  .then((res) => {
                    console.debug('addUnits', res.data);
                    setNewUnits(res.data);
                    resetForm();
                  })
                  .catch((err) => {})
                  .finally(() => {});
              }}
            >
              {({ values, errors, handleBlur, handleChange, touched }) => (
                <Form>
                  <FieldArray name="items">
                    {({ push, remove }) => (
                      <>
                        {values.items.map((item, index, arr) => (
                          <div
                            key={index}
                            className={`${baseClass}--addUnit-row`}
                          >
                            <span className={`${baseClass}--addUnit-row-title`}>
                              Unit number
                            </span>
                            <Input
                              className={`${baseClass}--addUnit-row-input`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Number"
                              name={`items.${index}.number`}
                              value={values.items[index].number}
                              errors={
                                getIn(errors, `items.${index}.number`) &&
                                getIn(touched, `items.${index}.number`)
                                  ? getIn(errors, `items.${index}.number`)
                                  : ''
                              }
                            />
                            {index === arr.length - 1 ? (
                              <Button
                                type="button"
                                theme={'secondary'}
                                className={`${baseClass}--addUnit-row-btn`}
                                onClick={() => push({ number: '' })} // Добавление нового поля
                              >
                                <i className="fa-solid fa-plus"></i>
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className={`${baseClass}--addUnit-btn`}
                                theme={'secondary'}
                                onClick={() => remove(index)}
                              >
                                <i className="fa-solid fa-minus"></i>
                              </Button>
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </FieldArray>
                  <div className={`${baseClass}--addUnit-buttons`}>
                    <Button type="submit">Save</Button>
                    <Button type={'reset'} theme={'secondary'}>
                      Clear
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Container>
        </Card.Body>
      </Card>
      <div className={`${baseClass}--delete`}>
        <Button
          className={`${baseClass}--delete-btn`}
          theme={'danger'}
          onClick={() => {
            // deleteProperty();
            setDeleteModal(true);
          }}
        >
          Delete property
        </Button>
      </div>
      <Dialog
        className={`${baseClass}--delete-dialog`}
        isOpen={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        isLoader={deleting}
        footer={
          <div className={`${baseClass}--delete-dialog-btns`}>
            <Button
              theme={'danger'}
              onClick={() => {
                deleteProperty();
              }}
            >
              Delete
            </Button>
            <Button
              theme={'secondary'}
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        }
      >
        Are you sure you want to delete this property?
      </Dialog>
    </div>
  );
};

export default EditProperty;
