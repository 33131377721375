import { io } from 'socket.io-client';
import { isDev, SocketResponse } from '@/types';

interface IException {
  status?: 'error';
  message: string;
  name?: string;
}

const clearedHost = window.location.host.replace(/:\d+/, '');

export const SOCKET_URL = isDev
  ? `${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}`
  : clearedHost;

const socket = io(SOCKET_URL, {
  // transports: ['polling', 'websocket'],
  transports: ['websocket'],
  withCredentials: true,
  extraHeaders: {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
  auth: {
    token: `Bearer ${localStorage.getItem('accessToken')}`,
  },
  reconnectionAttempts: 3,
});

socket.disconnect();

export function emitWithToken<T, E = string>(
  event: string,
  data: unknown,
): Promise<T> {
  return new Promise((resolve, reject) => {
    const token = `Bearer ${localStorage.getItem('accessToken')}`;
    socket.auth = { token, ...socket.auth };

    socket.emit(event, data, (response: SocketResponse<T, E>) => {
      // Проверяем ответ на возможные ошибки
      console.debug('emitWithToken response', response);
      if (response.error) {
        reject(response.error); // Отклоняем промис в случае ошибки
      } else {
        resolve(response.data); // Разрешаем промис с ответом
      }
    });
  });
}

socket.onAny((event, ...args) => {
  if (isDev) {
    console.debug('onAny', event, args);
  }
});

socket.on('connection', (s) => {
  console.debug('connection', s);
});

socket.on('connect', () => {
  const token = `Bearer ${localStorage.getItem('accessToken')}`;
  socket.auth = { token, ...socket.auth };
  if (isDev) {
    console.debug('connected');
  }
});

socket.on('disconnect', (reason, description) => {
  if (isDev) {
    console.debug('disconnected', reason, description);
  }
});

socket.on('error', (error: any) => {
  if (isDev) {
    console.debug('error', error);
  }
});

socket.on('connect_error', (error: any) => {
  if (isDev) {
    console.debug('connect_error', JSON.stringify(error, null, 2));
  }
});

socket.on('connect_timeout', (timeout: any) => {
  if (isDev) {
    console.debug('connect_timeout', timeout);
  }
});

socket.io.on('reconnect', (attemptNumber: any) => {
  if (isDev) {
    console.debug('reconnect', attemptNumber);
  }
});

socket.io.on('reconnect_attempt', (attemptNumber: any) => {
  if (isDev) {
    console.debug('reconnect_attempt', attemptNumber);
  }
});

socket.on('reconnecting', (attemptNumber: any) => {
  if (isDev) {
    console.debug('reconnecting', attemptNumber);
  }
});

socket.io.on('reconnect_error', (error: any) => {
  if (isDev) {
    console.debug('reconnect_error', error);
  }
});

socket.io.on('reconnect_failed', () => {
  if (isDev) {
    console.debug('reconnect_failed');
  }
});

socket.on('ping', () => {
  if (isDev) {
    console.debug('ping');
  }
});

socket.on('pong', (latency: any) => {
  if (isDev) {
    console.debug('pong', latency);
  }
});

socket.on('exception', (error: IException) => {
  console.debug('exception', error);
  if (error.name === 'TokenExpiredError') {
    if (isDev) {
      console.debug('jwt expired');
    }
    socket.emit('refresh_token', (data: { accessToken: string }) => {
      console.debug('refresh_token', data);
      if (data?.accessToken) {
        localStorage.setItem('accessToken', data.accessToken);
      }
    });
  }
});

socket.on('custom_event', (data: any) => {
  console.debug('custom_event', data);
});

export default socket;
