import { call, put, takeLatest } from 'redux-saga/effects';
import { type PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  fetchingProperty,
  fetchingPropertyError,
  fetchingPropertySuccess,
} from '@/store/property/propertySlices';
import { getPropertyRequest } from '@/api';
import { IProperty } from '@/types';
import handleRequestErrors from '@/utils/handleRequestErrors';

function* getPropertySaga(action: PayloadAction<number>) {
  const request = async (id: number) => getPropertyRequest(id);
  try {
    const response: AxiosResponse<IProperty> = yield call(
      request,
      action.payload,
    );
    yield put(fetchingPropertySuccess(response.data));
  } catch (error) {
    yield put(handleRequestErrors(error, fetchingPropertyError));
  }
}

function* watchGetPropertySaga() {
  yield takeLatest(fetchingProperty, getPropertySaga);
}

export default watchGetPropertySaga;
