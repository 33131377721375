import { call, put, takeLatest } from 'redux-saga/effects';
import { type PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { AuthErrorsResponse, AuthResponse, SigninDto } from '@/types';
import { signinRequest } from '@/api';
import {
  fetchingSignin,
  fetchingSigninError,
  fetchingSigninSuccess,
  setAuthTokens,
} from '@/store/auth/authSlices';
import handleRequestErrors from '@/utils/handleRequestErrors';

function* loginSaga(action: PayloadAction<SigninDto>) {
  const request = async (data: SigninDto) => signinRequest(data);
  try {
    const res: AxiosResponse<AuthResponse, AuthErrorsResponse> = yield call(
      request,
      action.payload,
    );
    const { accessToken } = res.data;
    // Сохранение токенов в Redux
    yield put(setAuthTokens(accessToken));
    yield put(fetchingSigninSuccess());
  } catch (error) {
    yield put(handleRequestErrors(error, fetchingSigninError));
    // if (axios.isAxiosError(error)) {
    //   yield put(
    //     fetchingSigninError(error.response?.data?.message || 'Logging failed'),
    //   );
    // } else {
    //   yield put(fetchingSigninError('An error occurred'));
    // }
  }
}

function* watchLoginSaga() {
  yield takeLatest(fetchingSignin, loginSaga);
}

export default watchLoginSaga;
