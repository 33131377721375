import { all } from 'redux-saga/effects';
import signupSaga from './auth/saga/signupSaga';
import loginSaga from './auth/saga/loginSaga';
import logoutSaga from '@/store/auth/saga/logoutSaga';
import getProfileSaga from '@/store/profile/saga/getProfileSaga';
import watchGetPropertySaga from '@/store/property/saga/getPropertySaga';
import watchCreatePropertySaga from '@/store/property/saga/createPropertySaga';
import watchGetPropertiesListSaga from '@/store/properties/saga/getPropertiesList';
import watchUpdatePropertySaga from '@/store/property/saga/updatePropertySaga';
import watchDeletePropertySaga from '@/store/property/saga/deletePropertySaga';

export default function* rootSaga() {
  yield all([
    ...signupSaga(),
    ...loginSaga(),
    ...logoutSaga(),
    ...getProfileSaga(),
    ...watchGetPropertySaga(),
    ...watchCreatePropertySaga(),
    ...watchGetPropertiesListSaga(),
    ...watchUpdatePropertySaga(),
    ...watchDeletePropertySaga(),
  ]);
}
