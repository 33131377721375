import React, { FC, useEffect, useRef } from 'react';
import clsx from 'clsx';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
} from 'react-leaflet';
import { FullscreenControl } from 'react-leaflet-fullscreen';

import L from 'leaflet';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-fullscreen/styles.css';
import './MapComponentStyles.scss';

// Исправляем пути к иконкам Leaflet
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const baseClass = 'MapComponent';

// interface IGeoData extends SearchResult {
//   raw: {
//     addresstype: 'building' | 'house' | 'town' | 'village' | 'city' | 'country';
//   };
//   label: string;
//   latitude?: number;
//   longitude?: number;
// }

interface IGeoData {
  raw?: {
    addresstype: 'building' | 'house' | 'town' | 'village' | 'city' | 'country';
    type: 'building' | 'house' | 'town' | 'village' | 'city' | 'country';
  };
  label: string;
  // latitude?: number;
  // longitude?: number;
  x?: number;
  y?: number;
}

interface IMapComponentProps {
  geoData?: IGeoData[] | IGeoData | null;
  className?: string;
}

const MapComponent: FC<IMapComponentProps> = ({ geoData, className }) => {
  const mapRef = useRef<L.Map | null>(null);
  const [geoDataArray, setGeoDataArray] = React.useState<IGeoData[]>([]);

  useEffect(() => {
    if (geoData) {
      setGeoDataArray(Array.isArray(geoData) ? geoData : [geoData]);
    } else {
      setGeoDataArray([]);
    }
  }, [geoData]);

  useEffect(() => {
    function onFullScreenChange() {
      if (mapRef.current) {
        mapRef.current.invalidateSize();
        if (document.fullscreenElement) {
          mapRef.current.scrollWheelZoom.enable();
        } else {
          mapRef.current.scrollWheelZoom.disable();
        }
      }
    }

    document.addEventListener('fullscreenchange', onFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', onFullScreenChange);
    };
  }, []);

  // Обновляем центр карты
  useEffect(() => {
    // console.log(geoDataArray);
    if (mapRef.current && geoDataArray.length > 0) {
      const arr = geoDataArray
        .filter((item) => {
          return item.y && item.x;
        })
        .map((item) => L.latLng(item.y || 0, item.x || 0));
      const bounds = L.latLngBounds(arr);
      if (arr.length > 0) {
        mapRef.current.fitBounds(bounds);
      }
    } else {
      if (mapRef.current) {
        mapRef.current.setView([0, 0], 1);
      }
    }
  }, [geoDataArray]);

  return (
    <MapContainer
      className={clsx(baseClass, className)}
      // center={{ lat: 43.6534817, lng: -79.3839347 }} // начальный центр
      zoom={10} // начальный уровень зума
      ref={mapRef}
      scrollWheelZoom={false}
      zoomControl={false}
      placeholder={<div>Loading...</div>}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <ZoomControl position="topleft" />
      {geoDataArray &&
        geoDataArray
          .filter((geoDataItem) => {
            if (!geoDataItem.y && !geoDataItem.x) {
              return false;
            }
            const arr = ['building', 'house'];
            const isAddressType = arr.some(
              (i) => geoDataItem.raw?.addresstype === i,
            );
            const isType = arr.some((i) => geoDataItem.raw?.type === i);
            return isAddressType || isType;
            // return ['building', 'house'].includes(
            //   geoDataItem.raw?.addresstype || geoDataItem.raw?.type || '',
            // );
          })
          .map((geoDataItem, index) => {
            return (
              <Marker
                key={index}
                position={{ lat: geoDataItem.y || 0, lng: geoDataItem.x || 0 }}
              >
                <Popup
                  className={clsx(
                    `${baseClass}--popup`,
                    className && `${className}--popup`,
                  )}
                >
                  {geoDataItem.label}
                </Popup>
              </Marker>
            );
          })}
      <FullscreenControl forceSeparateButton position={'topright'} />
    </MapContainer>
  );
};

export default MapComponent;
