import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import './Dialog.scss';

const baseClass = 'Dialog';

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: React.ReactNode;
  position?: 'center' | 'left' | 'right';
  footer?: React.ReactNode;
  className?: string;
  onOpen?: () => void;
  isLoader?: boolean;
}

const Dialog: FC<IModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  footer,
  position = 'center',
  className = '',
  onOpen,
  isLoader = false,
}) => {
  const portalKey = 'dialog-portal';
  useEffect(() => {
    // Функция для обработки нажатия клавиш
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    // Добавляем слушатель события нажатия клавиши
    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    // Убираем слушатель при размонтировании компонента или закрытии модалки
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen();
    }
  }, [isOpen, onOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      // className={`${baseClass}-backdrop ${baseClass}-backdrop-${position}`}
      className={clsx(
        `${baseClass}-backdrop`,
        `${baseClass}-backdrop-${position}`,
        className && `${className}-backdrop`,
        className && `${className}-backdrop-${position}`,
      )}
      onClick={onClose}
    >
      <div
        // className={baseClass}
        className={clsx(
          baseClass,
          `${baseClass}-${position}`,
          isLoader && `${baseClass}-loader`,
          className,
          className && `${className}-${position}`,
          className && isLoader && `${className}-loader`,
        )}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          // className={`${baseClass}-close`}
          className={clsx(
            `${baseClass}-close`,
            className && `${className}-close`,
          )}
          onClick={onClose}
          aria-label="Close"
        >
          &times;
        </button>
        {title && (
          <div
            className={clsx(
              `${baseClass}-title`,
              className && `${className}-title`,
            )}
          >
            {title}
          </div>
        )}
        <div
          className={clsx(
            `${baseClass}-content`,
            className && `${className}-content`,
          )}
        >
          <div
            className={clsx(
              `${baseClass}-content-children`,
              className && `${className}-content-children`,
            )}
          >
            {children}
          </div>
        </div>
        {footer && (
          <div
            className={clsx(
              `${baseClass}-footer`,
              className && `${className}-footer`,
            )}
          >
            {footer}
          </div>
        )}
      </div>
    </div>,
    document.body,
    portalKey,
  );
};

export default Dialog;
