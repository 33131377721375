import profile from './profile/profileSlices';
import auth from './auth/authSlices';
import properties from './properties/propertiesSlices';
import property from './property/propertySlices';

const rootReducer = {
  profile,
  auth,
  properties,
  property,
};

export default rootReducer;
