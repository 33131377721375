import {
  deletingProperty,
  deletingPropertyError,
  deletingPropertySuccess,
} from '@/store/property/propertySlices';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { deletePropertyRequest } from '@/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

function* deletePropertySaga(action: PayloadAction<number>) {
  const request = async (id: number) => deletePropertyRequest(id);
  try {
    const response: AxiosResponse = yield call(request, action.payload);
    yield put(deletingPropertySuccess(response.data));
  } catch (error) {
    yield all([put(deletingPropertyError(error))]);
  }
}

function* watchDeletePropertySaga() {
  yield takeLatest(deletingProperty, deletePropertySaga);
}

export default watchDeletePropertySaga;
