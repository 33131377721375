import { call, put, takeLatest } from 'redux-saga/effects';
import { type PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  createProperty,
  createPropertyError,
  createPropertySuccess,
} from '@/store/property/propertySlices';
import { createPropertyRequest } from '@/api';
import { AddPropertyValues } from '@/types';

function* createPropertySaga(action: PayloadAction<AddPropertyValues>) {
  const request = async (params: AddPropertyValues) =>
    createPropertyRequest(params);
  try {
    const response: AxiosResponse = yield call(request, action.payload);
    yield put(createPropertySuccess(response.data));
  } catch (error) {
    yield put(createPropertyError(error));
  }
}

function* watchCreatePropertySaga() {
  yield takeLatest(createProperty, createPropertySaga);
}

export default watchCreatePropertySaga;
