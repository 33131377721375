import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './ConfirmEmail.scss';
import { confirmEmailRequest } from '@/api';

const baseClass = 'ConfirmEmail';

const ConfirmEmail = () => {
  const { token } = useParams(); // Получение токена из URL
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Функция для запроса на подтверждение email
    const confirmEmail = async (token: string) => {
      try {
        const response = await confirmEmailRequest(token);
        setMessage(response.data.message);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e: any) {
        if (e?.response?.status === 400) {
          setMessage('Email is already confirmed.');
        } else if (e?.message) {
          setMessage(e?.message);
        } else {
          setMessage(
            'Error confirming email. The link may be expired or invalid.',
          );
        }
      }
    };

    if (token) {
      confirmEmail(token);
    }
  }, [token]);

  return (
    <div className={baseClass}>
      <h2>{message}</h2>
      <Link to={'/login'}>Back</Link>
    </div>
  );
};

export default ConfirmEmail;
