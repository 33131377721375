import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import './PropertyUnitsTable.scss';
import { IProperty, IUnit } from '@/types';
import Button from '@/components/Button';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

const baseClass = 'PropertyUnitsTable';

function createData(
  number: number,
  vacancy: string,
  tenant: string,
  rent: number,
  payment: string,
) {
  return { number, vacancy, tenant, rent, payment };
}

interface PropertyUnitsTableProps {
  property: IProperty | null | undefined;
  newUnits?: IUnit[];
}

export default function PropertyUnitsTable({
  property,
  newUnits = [],
}: PropertyUnitsTableProps) {
  const navigate = useNavigate();
  console.debug(property);
  return (
    <TableContainer component={Paper} elevation={0} className={baseClass}>
      <Table sx={{ minWidth: '300px' }} aria-label="simple table">
        <TableHead className={`${baseClass}--head`}>
          <TableRow className={`${baseClass}--head-tr`}>
            <TableCell className={`${baseClass}--head-th`}>
              <div className={`${baseClass}--head-th-content`}>
                <div className={`${baseClass}--head-th-label`}>NUMBER</div>
                <div className={`${baseClass}--head-th-btns`}>
                  <i
                    className={`fa-solid fa-sort-up ${baseClass}--head-th-btns-icon active`}
                  ></i>
                  <i
                    className={`fa-solid fa-sort-down  ${baseClass}--head-th-btns-icon`}
                  ></i>
                </div>
              </div>
            </TableCell>
            <TableCell align="left" className={`${baseClass}--head-th`}>
              <div className={`${baseClass}--head-th-label`}>
                <div className={`${baseClass}--head-th-content`}>
                  <div className={`${baseClass}--head-th-label`}>VACANCY</div>
                  <div className={`${baseClass}--head-th-btns`}>
                    <i
                      className={`fa-solid fa-sort-up ${baseClass}--head-th-btns-icon `}
                    ></i>
                    <i
                      className={`fa-solid fa-sort-down  ${baseClass}--head-th-btns-icon`}
                    ></i>
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell align="left" className={`${baseClass}--head-th`}>
              <div className={`${baseClass}--head-th-label`}>
                <div className={`${baseClass}--head-th-content`}>
                  <div className={`${baseClass}--head-th-label`}>TENANT</div>
                  <div className={`${baseClass}--head-th-btns`}>
                    <i
                      className={`fa-solid fa-sort-up ${baseClass}--head-th-btns-icon `}
                    ></i>
                    <i
                      className={`fa-solid fa-sort-down  ${baseClass}--head-th-btns-icon`}
                    ></i>
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell align="left" className={`${baseClass}--head-th`}>
              <div className={`${baseClass}--head-th-label`}>
                <div className={`${baseClass}--head-th-content`}>
                  <div className={`${baseClass}--head-th-label`}>RENT</div>
                  <div className={`${baseClass}--head-th-btns`}>
                    <i
                      className={`fa-solid fa-sort-up ${baseClass}--head-th-btns-icon `}
                    ></i>
                    <i
                      className={`fa-solid fa-sort-down  ${baseClass}--head-th-btns-icon`}
                    ></i>
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell align="left" className={`${baseClass}--head-th`}>
              <div className={`${baseClass}--head-th-label`}>
                <div className={`${baseClass}--head-th-content`}>
                  <div className={`${baseClass}--head-th-label`}>PAYMENT</div>
                  <div className={`${baseClass}--head-th-btns`}>
                    <i
                      className={`fa-solid fa-sort-up ${baseClass}--head-th-btns-icon`}
                    ></i>
                    <i
                      className={`fa-solid fa-sort-down  ${baseClass}--head-th-btns-icon`}
                    ></i>
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell
              align="center"
              className={`${baseClass}--head-th`}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[
            ...(property?.address?.units || []),
            ...newUnits,
            {
              number: '1test',
              rentPrice: 100,
              payment: 'paid',
              tenant: {
                avatar:
                  'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Cat03.jpg/1280px-Cat03.jpg',
                email: 'test@email.tets',
                firstname: 'Foo',
                lastname: 'Barrr',
              },
            },
          ].map((row) => (
            <TableRow
              className={`${baseClass}--row`}
              key={row.number}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={() => {
                console.debug(row);
              }}
            >
              <TableCell scope="row" className={`${baseClass}--row-cell`}>
                {row.number}
              </TableCell>
              <TableCell align="center" className={`${baseClass}--row-cell`}>
                {row.tenant ? (
                  <span className={`${baseClass}--row-cell-vacancy leased`}>
                    LEASED
                  </span>
                ) : (
                  <span className={`${baseClass}--row-cell-vacancy`}>
                    VACANT
                  </span>
                )}
              </TableCell>
              <TableCell align="center" className={`${baseClass}--row-cell`}>
                {row.tenant ? (
                  <div className={`${baseClass}--row-cell-profile`}>
                    <div className={`${baseClass}--row-cell-profile-avatar`}>
                      {row.tenant?.avatar ? (
                        <img
                          src={row.tenant?.avatar || ''}
                          alt="avatar"
                          className={`${baseClass}--row-cell-profile-avatar-img`}
                        />
                      ) : (
                        <div
                          className={`${baseClass}--row-cell-profile-avatar-img`}
                        >
                          <i className="fa-solid fa-circle-user"></i>
                        </div>
                      )}
                    </div>
                    <div className={`${baseClass}--row-cell-profile-info`}>
                      <div
                        className={`${baseClass}--row-cell-profile-info-name`}
                      >
                        {row.tenant?.firstname} {row.tenant?.lastname}
                      </div>
                      <div
                        className={`${baseClass}--row-cell-profile-info-email`}
                      >
                        {row.tenant?.email}
                      </div>
                    </div>
                    <Button
                      className={`${baseClass}--row-cell-profile-btn leased`}
                      onClick={() => {
                        // navigate('/');
                      }}
                    >
                      <i className="fa-solid fa-user"></i>
                    </Button>
                  </div>
                ) : (
                  <div className={`${baseClass}--row-cell-profile`}>
                    <div
                      className={`${baseClass}--row-cell-profile-avatar`}
                    ></div>
                    <div
                      className={`${baseClass}--row-cell-profile-info`}
                    ></div>
                    <Button
                      className={`${baseClass}--row-cell-profile-btn vacant`}
                      onClick={() => {
                        // navigate('/');
                      }}
                    >
                      <i className="fa-solid fa-user-plus"></i>
                    </Button>
                  </div>
                )}
              </TableCell>
              <TableCell align="center" className={`${baseClass}--row-cell`}>
                {row.rentPrice ? (
                  <div className={`${baseClass}--row-cell-rent`}>
                    <div
                      className={`${baseClass}--row-cell-rent-price`}
                    >{`$ ${row.rentPrice}`}</div>
                    <div className={`${baseClass}--row-cell-rent-period`}>
                      per month
                    </div>
                  </div>
                ) : (
                  <div className={`${baseClass}--row-cell-rent`}>-</div>
                )}
              </TableCell>
              <TableCell align="left" className={`${baseClass}--row-cell`}>
                <span
                  className={clsx(
                    `${baseClass}--row-cell-payment`,
                    row.payment,
                  )}
                >
                  {row.payment || '-'}
                </span>
              </TableCell>
              <TableCell align="center" className={`${baseClass}--row-cell`}>
                <div className={`${baseClass}--unit`}>
                  <Button className={`${baseClass}--unit-btn info`}>
                    <i className="fa-solid fa-circle-info"></i>
                  </Button>
                  <Button className={`${baseClass}--unit-btn`}>
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
