import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { getProfileRequest } from '@/api';
import {
  fetchingProfile,
  fetchProfileFailure,
  fetchProfileSuccess,
} from '@/store/profile/profileSlices';
import handleRequestErrors from '@/utils/handleRequestErrors';
import { IUser } from '@/types';

function* getProfileSaga() {
  const request = async () => getProfileRequest();
  try {
    const res: AxiosResponse<IUser> = yield call(request);
    yield put(fetchProfileSuccess(res.data));
  } catch (error) {
    yield put(handleRequestErrors(error, fetchProfileFailure));
  }
}

function* watchGetProfileSaga() {
  yield takeLatest(fetchingProfile, getProfileSaga);
}

export default watchGetProfileSaga;
