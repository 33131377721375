import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@/components/Button';
import Card from '@/components/Card';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchingProperties,
  ownedPropertiesSelector,
  propertiesLoadingSelector,
} from '@/store/properties/propertiesSlices';
import MapComponent from '@/components/MapComponent';
import './Properties.scss';
import PropertyTable from '@/components/PropertyTable';

const baseClass = 'Properties';

const Properties = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const tenantProperties = useSelector(tenantPropertiesSelector);
  const ownedProperties = useSelector(ownedPropertiesSelector);
  const isLoading = useSelector(propertiesLoadingSelector);

  const geoData = useMemo(() => {
    return ownedProperties.map((property) => {
      return {
        label: property.name,
        x: property.address?.longitude || 0,
        y: property.address?.latitude || 0,
        raw: {
          addresstype: 'building' as const,
          type: 'house' as const,
        },
      };
    });
  }, [ownedProperties]);

  useEffect(() => {
    dispatch(fetchingProperties());
  }, [dispatch]);

  return (
    <div className={baseClass}>
      <Card className={`${baseClass}--add`}>
        <Card.Title className={`${baseClass}--add-title`}>
          <span>List of properties</span>
          <Button
            onClick={() => {
              navigate('/properties/add');
            }}
          >
            Add property
          </Button>
        </Card.Title>
        <Card.Body>
          <MapComponent geoData={geoData} className={`${baseClass}--map`} />
          <PropertyTable properties={ownedProperties} isLoading={isLoading} />
        </Card.Body>
      </Card>
      {/*{isLoading &&*/}
      {/*  ownedProperties.length === 0 &&*/}
      {/*  tenantProperties.length === 0 && (*/}
      {/*    <Card className={`${baseClass}--list`}>*/}
      {/*      <Card.Title>Loading...</Card.Title>*/}
      {/*      <Card.Body>*/}
      {/*        <Skeleton isLoading={isLoading} style={{ width: '100%' }} />*/}
      {/*      </Card.Body>*/}
      {/*    </Card>*/}
      {/*  )}*/}
      {/*{ownedProperties.length > 0 && (*/}
      {/*  <Card className={`${baseClass}--list`}>*/}
      {/*    <Card.Title>Your Rental Properties</Card.Title>*/}
      {/*    <Card.Body>*/}
      {/*      <PropertyTable properties={ownedProperties} isLoading={isLoading} />*/}
      {/*    </Card.Body>*/}
      {/*  </Card>*/}
      {/*)}*/}
      {/*{tenantProperties.length > 0 && (*/}
      {/*  <Card className={`${baseClass}--list`}>*/}
      {/*    <Card.Title>Your Rented Properties</Card.Title>*/}
      {/*    <Card.Body>*/}
      {/*      <PropertyTable*/}
      {/*        properties={tenantProperties}*/}
      {/*        isLoading={isLoading}*/}
      {/*      />*/}
      {/*    </Card.Body>*/}
      {/*  </Card>*/}
      {/*)}*/}
    </div>
  );
};

export default Properties;
