import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import {
  AddPropertyValues,
  AuthResponse,
  Chat,
  ChatsResponse,
  FetchAllPropertiesResponse,
  IProperty,
  isDev,
  IUnit,
  IUser,
  Message,
  SendMessageRequest,
  SigninDto,
  SignupDto,
  UpdatePropertyRequest,
} from '@/types';

export const API_URL = isDev
  ? `http://${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}/api`
  : '/api';

export const FRONTEND_URL = `${process.env.REACT_APP_FRONTEND_HOST}:${process.env.REACT_APP_FRONTEND_PORT}`;

const $api = axios.create({
  withCredentials: true,
  // baseURL: API_URL,
  baseURL: API_URL,
});

$api.interceptors.request.use(
  (
    config: InternalAxiosRequestConfig,
  ): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    return config;
  },
);

const notRefreshUrls = ['/login', '/logout', '/register'];

$api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !notRefreshUrls.includes(originalRequest.url)
    ) {
      originalRequest._retry = true;
      // console.log('401 error:', error);

      try {
        originalRequest._isRefresh = true;
        const response = await axios.post(
          `${API_URL}/refresh-token`,
          {},
          { withCredentials: true },
        );
        const { accessToken } = response.data;

        localStorage.setItem('accessToken', accessToken);
        $api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

        return $api(originalRequest);
      } catch (err) {
        localStorage.removeItem('accessToken');
        // window.location.replace('/login');
        console.error('Refresh token error:', err);
        console.log(originalRequest);
        console.log(error);
        if (originalRequest._isRefresh) {
          window.location.replace('/login');
        }
      }
    } else if (error.response.status === 401 && originalRequest._retry) {
      localStorage.removeItem('accessToken');
      window.location.replace('/login');
    }

    return Promise.reject(error);
  },
);

// Добавляем перехватчик для обработки ошибок
$api.interceptors.response.use(
  (response) => {
    // Возвращаем ответ, если все прошло успешно
    return response;
  },
  (error) => {
    if (!error.response) {
      // Обработка ошибок сети (например, если сервер отключен)
      console.error('Ошибка сети: сервер недоступен.');
      // window.location.replace('/unable-to-connect');
      return Promise.reject({
        status: 'error',
        message: 'Сервер недоступен. Попробуйте позже.',
      });
    }

    // Обработка ошибок с ответом от сервера
    const statusCode = error.response.status;
    switch (statusCode) {
      case 500:
        console.error('Ошибка сервера');
        break;
      case 404:
        console.error('Ресурс не найден');
        break;
      default:
        console.error('Ошибка', error.response.data.message);
    }

    return Promise.reject(error.response.data);
  },
);

export default $api;

// ----- AUTH API ----->

export async function signupRequest(
  data: SignupDto,
): Promise<AxiosResponse<AuthResponse>> {
  return $api.post('/register', data);
}

export async function signinRequest(
  data: SigninDto,
): Promise<AxiosResponse<AuthResponse>> {
  return $api.post<AuthResponse>('/login', data);
}

export async function logoutRequest() {
  return $api.post('/logout');
}

export async function getProfileRequest() {
  return $api.get('/profile');
}

// ----- PROPERTIES API ----->

export async function getPropertyRequest(id: number) {
  return $api.get('/properties/' + id);
}

export async function getPropertiesRequest(): Promise<
  AxiosResponse<FetchAllPropertiesResponse>
> {
  return $api.get('/properties');
}

export async function createPropertyRequest(
  data: AddPropertyValues,
): Promise<AxiosResponse<IProperty>> {
  return $api.post('/properties', data);
}

export async function updatePropertyRequest({
  id,
  data,
}: UpdatePropertyRequest): Promise<AxiosResponse<IProperty>> {
  return $api.patch('/properties/' + id, data);
}

export async function deletePropertyRequest(id: number) {
  return $api.delete('/properties/' + id);
}

// ----- UNITS API ----->

export async function createUnitsNumbersRequest(
  propertyId: number,
  data: { number: string }[],
) {
  return $api.post(`/properties/${propertyId}/units`, data);
}

export async function createUnitRequest(propertyId: number, data: IUnit) {
  // return $api.post(`/properties/${propertyId}/unit`, data); // TODO split data for sections with id and number
  return data;
}

// ----- TENANTS API ----->

export async function confirmEmailRequest(token: string) {
  return $api.get(`/auth/confirm/${token}`);
}

export async function linkPropertyRequest(token: string) {
  return $api.post(`/invite/use/${token}`);
}

export async function createInviteRequest(propertyId: number) {
  return $api.post(`/invite/create/${propertyId}`);
}

export async function deleteInviteRequest(propertyId: number) {
  return $api.delete(`/invite/delete/${propertyId}`);
}

export async function deleteTenantRequest(
  propertyId: number,
  tenantId: number,
) {
  return $api.delete(`/properties/${propertyId}/tenants/${tenantId}`);
}

export async function updateProfileRequest(
  data: Partial<IUser>,
): Promise<AxiosResponse<IUser>> {
  return $api.patch<IUser>('/profile', data);
}

export async function getParticipantProfile(
  propertyId: number,
  userId: number,
): Promise<AxiosResponse<IUser>> {
  return $api.get(`/properties/${propertyId}/profile/${userId}`);
}

// ---- CHATS API ---->

export async function getChatsRequest(): Promise<
  AxiosResponse<ChatsResponse[]>
> {
  return $api.get('/chats');
}

export async function getChatRequest(
  chatId: number,
): Promise<AxiosResponse<Chat>> {
  return $api.get(`/chats/${chatId}`);
}

export async function createChatRequest(participantsIds: number[]) {
  return $api.post('/chats/create', participantsIds);
}

export async function addUserToChatRequest(chatId: number, userId: number) {
  return $api.post(`/chats/${chatId}/add-user/${userId}`);
}

export async function getChatMessagesRequest(
  chatId: number,
): Promise<AxiosResponse<Message[]>> {
  return $api.get(`/chats/${chatId}/messages`);
}

export async function sendMessageRequest(
  chatId: number,
  data: SendMessageRequest,
) {
  return $api.post(`/chats/${chatId}/send-message`, data);
}

export async function createMessageRequest(chatId: number, text: string) {
  return $api.post(`/chats/${chatId}/messages`, { text });
}

export async function readMessageRequest(chatId: number, messageId: number) {
  return $api.patch(`/chats/${chatId}/messages/${messageId}`);
}

export async function deleteMessageRequest(chatId: number, messageId: number) {
  return $api.delete(`/chats/${chatId}/messages/${messageId}`);
}

export async function deleteChatRequest(chatId: number) {
  return $api.delete(`/chats/${chatId}`);
}

export async function getChatParticipantsRequest(chatId: number) {
  return $api.get(`/chats/${chatId}/participants`);
}
