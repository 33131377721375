import React, { FC, useEffect, useRef, useState } from 'react';
import './FileUploader.scss';
import Button from '@/components/Button';

const baseClass = 'FileUploader';

interface FileUploaderProps {
  onFilesSelect: (files: File[]) => void;
  acceptFiles?: string[];
  filesLimit?: number;
  maxFileSize?: number;
  className?: string;
  files: File[] | File | null;
  filesDescription?: string;
}

const FileUploader: FC<FileUploaderProps> = ({
  onFilesSelect,
  acceptFiles = [],
  filesLimit = 1,
  maxFileSize = 100000000,
  className = '',
  files = [],
  filesDescription = '',
}) => {
  const [filesState, setFilesState] = useState<File[]>([]);
  const [dragActive, setDragActive] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!files) {
      setFilesState([]);
    } else if (files && Array.isArray(files)) {
      setFilesState(files);
    } else {
      setFilesState([files]);
    }
  }, [files]);

  const handleFile = (files: FileList) => {
    const selectedFiles = Array.from(files).filter((file) => {
      // Проверяем на максимальный размер файла
      if (file.size > maxFileSize) {
        alert(
          `File ${file.name} exceeds the maximum size of ${maxFileSize / 1000000} MB`,
        );
        return false; // Не добавляем файл, если он слишком большой
      }
      return true; // Добавляем файл, если он меньше максимального размера
    });

    // Проверяем на лимит количества файлов
    if (filesState.length + selectedFiles.length > filesLimit) {
      alert(`You can only upload up to ${filesLimit} files`);
      return;
    }

    const updatedFiles = [...filesState, ...selectedFiles];
    setFilesState(updatedFiles);
    onFilesSelect(updatedFiles); // Уведомляем родителя о выбранных файлах
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      handleFile(event.dataTransfer.files);
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      handleFile(event.target.files);
    }
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = filesState.filter((_, i) => i !== index);
    setFilesState(updatedFiles);
    onFilesSelect(updatedFiles); // Уведомляем родителя об обновлении файлов
  };

  return (
    <div
      className={`${baseClass} ${dragActive ? 'drag-active' : ''}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className={`${baseClass}__content`}>
        <p className={`${baseClass}__text`}>
          Drag and drop document here <br /> <span>or</span>
        </p>
        <input
          type="file"
          // id="file-input"
          ref={fileInputRef}
          accept={acceptFiles.join(',')}
          onChange={handleFileSelect}
          hidden
          multiple={filesLimit > 1}
        />
        <Button
          onClick={() => fileInputRef.current?.click()}
          className={`${baseClass}__btn`}
        >
          Select a file
        </Button>
        <p className={`${baseClass}__subtext`}>
          {filesDescription || 'XLS, CSV / max 100 MB'}
        </p>
        {filesState.length > 0 && (
          <div className={`${baseClass}__files`}>
            {filesState.map((file, index) => (
              <div key={index} className={`${baseClass}__file`}>
                <span className={`${baseClass}__file-name`}>{file.name}</span>
                <i
                  className={`fa-solid fa-trash-can ${baseClass}__file-remove`}
                  onClick={() => handleRemoveFile(index)}
                ></i>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUploader;
