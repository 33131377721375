import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchingProperty,
  propertyLoadingSelector,
  propertySelector,
} from '@/store/property/propertySlices';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Skeleton from '@/components/Skeleton';
import { profileSelector } from '@/store/profile/profileSlices';
import { emitWithToken } from '@/api/socket';
import { WsException } from '@/types';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import UnitsListCard from '@/components/UnitsListCard';
import PropertyOverviewCard from '@/components/PropertyOverviewCard';
import './Property.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

const dataUnits = {
  // labels: ['Red', 'Blue', 'Yellow'],
  // labels: [],
  datasets: [
    {
      // label: '# of Votes',
      data: [160, 24],
      backgroundColor: ['#5CB2EF', '#105E96'],
      borderColor: ['#5CB2EF', '#105E96'],
      borderWidth: 1,
    },
  ],
};

const dataPaid = {
  // labels: ['Red', 'Blue', 'Yellow'],
  // labels: [],
  datasets: [
    {
      // label: '# of Votes',
      data: [12, 19, 20],
      backgroundColor: ['#76C102', '#FFC107', '#FB2F54'],
      borderColor: ['#76C102', '#FFC107', '#FB2F54'],
      borderWidth: 1,
    },
  ],
};

const baseClass = 'PropertyPage';

const Property = () => {
  const { propertyId } = useParams<{ propertyId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const property = useSelector(propertySelector);
  const isPropertyLoading = useSelector(propertyLoadingSelector);
  const profile = useSelector(profileSelector);
  const numericId = Number(propertyId);
  const isNumeric = !isNaN(numericId);

  useEffect(() => {
    emitWithToken<string, WsException>('events', 'Property page')
      .then((data) => {
        console.debug('event callback', data);
      })
      .catch((error: WsException) => {
        console.debug('event error', error);
      });
  }, []);

  useEffect(() => {
    if (isNumeric) {
      dispatch(fetchingProperty(numericId));
    }
  }, [dispatch, propertyId, isNumeric, numericId]);

  if (!isNumeric || (!isPropertyLoading && !property)) {
    return (
      <Card className={baseClass}>
        <h4>Invalid property ID</h4>
        <Button onClick={() => navigate('/properties')}>
          Go back to properties
        </Button>
      </Card>
    );
  }

  return (
    <div className={baseClass}>
      <div className={`${baseClass}--title`}>
        <Skeleton isLoading={isPropertyLoading}>
          <span className={`${baseClass}--title-text`}>
            <span className={`${baseClass}--title-text-prop`}>Property:</span>{' '}
            {property?.name}
          </span>
        </Skeleton>
        {profile?.id === property?.owner?.id && (
          <div className={`${baseClass}--title-btn`}>
            <Button
              className={`${baseClass}--title-btn-item`}
              theme={'secondary'}
              onClick={() => {
                navigate(`/properties`);
              }}
            >
              Back
            </Button>
            <Button
              className={`${baseClass}--title-btn-item`}
              onClick={() => {
                navigate(`/properties/${propertyId}/edit`);
              }}
            >
              <span className={`${baseClass}--title-btn-item-content`}>
                <i className="fa-regular fa-pen-to-square"></i>
                <span>Edit</span>
              </span>
            </Button>
          </div>
        )}
      </div>
      <PropertyOverviewCard property={property} navButton={<span />} />
      <Card className={`${baseClass}--statistics`}>
        <Card.Header className={`${baseClass}--statistics-header`}>
          <Card.Title className={`${baseClass}--statistics-title`}>
            Property statistics
          </Card.Title>
        </Card.Header>
        <Card.Body className={`${baseClass}--statistics-body`}>
          <Card.Container className={`${baseClass}--statistics-container`}>
            <div className={`${baseClass}--statistics-info`}>
              <div className={`${baseClass}--statistics-info-charts`}>
                <div className={`${baseClass}--statistics-info-charts-item`}>
                  <div
                    className={`${baseClass}--statistics-info-charts-item-list`}
                  >
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-icon`}
                      data-icon="units"
                    ></div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-label`}
                    >
                      Units
                    </div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-value`}
                    >
                      111
                    </div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-icon`}
                      data-icon="leased"
                    ></div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-label`}
                    >
                      Leased
                    </div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-value`}
                    >
                      222
                    </div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-icon`}
                      data-icon="vacant"
                    ></div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-label`}
                    >
                      Vacant
                    </div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-value`}
                    >
                      333
                    </div>
                  </div>
                  <div
                    className={`${baseClass}--statistics-info-charts-item ${baseClass}--statistics-doughnut`}
                  >
                    <Doughnut
                      data={dataUnits}
                      className={`${baseClass}--statistics-doughnut`}
                      width={'100%'}
                      height={'100%'}
                    />
                  </div>
                </div>
                <div className={`${baseClass}--statistics-info-charts-item`}>
                  <div
                    className={`${baseClass}--statistics-info-charts-item-list`}
                  >
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-icon`}
                      data-icon="paid"
                    ></div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-label`}
                    >
                      Paid
                    </div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-value`}
                    >
                      1
                    </div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-icon`}
                      data-icon="arrears"
                    ></div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-label`}
                    >
                      Arrears
                    </div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-value`}
                    >
                      2
                    </div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-icon`}
                      data-icon="noset"
                    ></div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-label`}
                    >
                      No set
                    </div>
                    <div
                      className={`${baseClass}--statistics-info-charts-item-list-value`}
                    >
                      3
                    </div>
                  </div>
                  <div
                    className={`${baseClass}--statistics-info-charts-item ${baseClass}--statistics-doughnut`}
                  >
                    <Doughnut
                      data={dataPaid}
                      className={`${baseClass}--statistics-doughnut`}
                      width={'100%'}
                      height={'100%'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card.Container>
        </Card.Body>
      </Card>
      <UnitsListCard property={property} />
    </div>
  );
};

export default Property;
