import React, { FC, useEffect } from 'react';
import Card from '@/components/Card';
import { getChatsRequest } from '@/api';
import { ChatsResponse } from '@/types';
import ChatDialog from '@/components/ChatDialog';
import './ChatsPage.scss';

const baseClass = 'ChatsPage';

interface IChatItemProps {
  id: number;
  message?: string | null;
  title?: string | null;
  onClick: (id: number) => void;
}

const ChatListItem: FC<IChatItemProps> = ({ id, message, title, onClick }) => {
  return (
    <div className={`${baseClass}--item`} onClick={() => onClick(id)}>
      <div className={`${baseClass}--item-left`}>
        {/*<img*/}
        {/*  className={`${baseClass}--item-left-image`}*/}
        {/*  src="https://www.animalcarectr.com/blog/images/fluffy_grey_cat.jpg"*/}
        {/*  alt="User"*/}
        {/*/>*/}
        <i className={`fa-solid fa-building ${baseClass}--item-left-icon`}></i>
      </div>
      <div className={`${baseClass}--item-right`}>
        <div className={`${baseClass}--item-right-name`}>{title}</div>
        <div className={`${baseClass}--item-right-message`}>{message}</div>
      </div>
    </div>
  );
};

const ChatsPage = () => {
  const [selectedChatId, setSelectedChatId] = React.useState<number | null>(
    null,
  );
  const [chats, setChats] = React.useState<ChatsResponse[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onClick = (id: number) => {
    setSelectedChatId(id);
  };

  useEffect(() => {
    setIsLoading(true);
    getChatsRequest()
      .then((response) => {
        if (response.status === 200) {
          console.debug(response.data);
          setChats(response.data || []);
        } else {
          console.error('Error');
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className={baseClass}>
      <ChatDialog
        chatId={selectedChatId}
        onClose={() => setSelectedChatId(null)}
      />
      <Card className={`${baseClass}-card`}>
        {/*<Card.Title className={`${baseClass}-card-title`}>Messages</Card.Title>*/}
        <Card.Body className={`${baseClass}-card-body`}>
          {chats.map((chat) => (
            <ChatListItem
              key={chat.id}
              id={chat.id}
              title={chat.title}
              message={chat.message}
              onClick={onClick}
            />
          ))}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ChatsPage;
