import React from 'react';
import Card from '@/components/Card';
import FullCalendar from '@fullcalendar/react';
import { formatDate } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import './CalendarPage.scss';

const baseClass = 'CalendarPage';
const CalendarPage = () => {
  return (
    <div className={baseClass}>
      <Card className={`${baseClass}-card`}>
        <Card.Body className={`${baseClass}-card-body`}>
          {/*// @ts-ignore*/}
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            // headerToolbar={{
            //   left: 'prev,next today',
            //   center: 'title',
            //   right: 'dayGridMonth,timeGridWeek,timeGridDay',
            // }}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default CalendarPage;
