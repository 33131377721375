import React, { useEffect } from 'react';
import Card from '@/components/Card';
import Input from '@/components/Input';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchingProfile,
  fetchProfileSuccess,
  profileLoadingSelector,
  profileSelector,
} from '@/store/profile/profileSlices';
import * as Yup from 'yup';
import { Form, Formik, getIn } from 'formik';
import Button from '@/components/Button';
import { IAddress, IUser } from '@/types';
import { updateProfileRequest } from '@/api';
import getAxiosErrors from '@/utils/getAxiosErrors';
import './ProfilePage.scss';

const emailValidationSchema = Yup.object({
  email: Yup.string().email('Invalid email').required('Email is required'),
});

const emailInitialValues = {
  email: '',
};

const addressInitialValues: Partial<IAddress> = {
  country: '',
  stateOrProvince: null,
  city: null,
  street: null,
  buildingNumber: null,
  // apartmentNumber: null,
  postalCode: null,
  latitude: null,
  longitude: null,
  // phone: null,
};

const initialValues: Partial<IUser> = {
  firstname: '',
  lastname: '',
  // email: '',
  phone: '',
  addresses: addressInitialValues,
};

const validationSchema = Yup.object().shape({
  firstname: Yup.string().trim().required('Firstname is required'),
  lastname: Yup.string().trim().required('Lastname is required'),
  email: Yup.string()
    .trim()
    .email('Invalid email')
    .required('Email is required'),
  phone: Yup.string()
    .nullable()
    .trim()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    }),
  addresses: Yup.object().shape({
    country: Yup.string().required('Country is required'),
    state: Yup.string()
      .nullable()
      .trim()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      }),
    city: Yup.string()
      .nullable()
      .trim()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      }),
    street: Yup.string()
      .nullable()
      .trim()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      }),
    houseNumber: Yup.string()
      .nullable()
      .trim()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      }),
    apartmentNumber: Yup.string()
      .nullable()
      .trim()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      }),
    postalCode: Yup.string()
      .nullable()
      .trim()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      }),
    latitude: Yup.string()
      .nullable()
      .trim()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      }),
    longitude: Yup.string()
      .nullable()
      .trim()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      }),
    phone: Yup.string()
      .nullable()
      .trim()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      }),
  }),
});

const baseClass = 'ProfilePage';

const ProfilePage = () => {
  const profile = useSelector(profileSelector);
  const isLoading = useSelector(profileLoadingSelector);
  const dispatch = useDispatch();

  // TODO: Implement the ProfilePage component with update data
  useEffect(() => {
    dispatch(fetchingProfile());
  }, [dispatch]);

  return (
    <div className={baseClass}>
      <Card className={`${baseClass}--email`}>
        <Card.Header>
          <Card.Title>Email</Card.Title>
          <Card.TitleDescription>
            <p>
              Your current primary email address is{' '}
              <span>{profile?.email}</span>{' '}
              {!profile?.isEmailConfirmed && '(not confirmed)'}
            </p>
          </Card.TitleDescription>
        </Card.Header>
        <Card.Body>
          <Formik
            enableReinitialize={true}
            validationSchema={emailValidationSchema}
            initialValues={
              profile ? { email: profile.email } : emailInitialValues
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
              console.log(values);
              setSubmitting(true);
              updateProfileRequest(values)
                .then((res) => {
                  dispatch(fetchProfileSuccess(res.data));
                })
                .catch((err) => {
                  setErrors(getAxiosErrors(err));
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
            // onReset={(va) => {
            //   console.log(va, 'reset');
            // }}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              touched,
              isSubmitting,
            }) => (
              <Form>
                <Input
                  label={'New email'}
                  // type={'email'}
                  name={'email'}
                  floatLabel={true}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // errors={errors.email && touched.email ? errors.email : ''}
                  errors={
                    getIn(errors, 'email') && getIn(touched, 'email')
                      ? getIn(errors, 'email')
                      : ''
                  }
                />
                <div className={`${baseClass}--profile-btns`}>
                  <Button
                    type={'submit'}
                    isDisabled={isSubmitting || isLoading}
                  >
                    Save
                  </Button>
                  <Button type={'reset'}>Reset</Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
      <Card className={`${baseClass}--profile`}>
        <Card.Header>
          <Card.Title>Profile information</Card.Title>
          <Card.TitleDescription>
            Update some personal information. Your address will never be
            publicly available.
          </Card.TitleDescription>
        </Card.Header>
        <Card.Body>
          <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={
              profile && profile.addresses
                ? profile
                : {
                    ...profile,
                    addresses: addressInitialValues || initialValues,
                  }
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
              setSubmitting(true);
              updateProfileRequest(values)
                .then((res) => {
                  dispatch(fetchProfileSuccess(res.data));
                })
                .catch((err) => {
                  setErrors(getAxiosErrors(err));
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              touched,
              isSubmitting,
            }) => (
              <Form>
                {/*<Input*/}
                {/*  label={'Username'}*/}
                {/*  type={'text'}*/}
                {/*  name={'username'}*/}
                {/*  floatLabel={true}*/}
                {/*  value={values.username}*/}
                {/*  onChange={handleChange}*/}
                {/*  onBlur={handleBlur}*/}
                {/*  errors={*/}
                {/*    getIn(errors, 'username') && getIn(touched, 'username')*/}
                {/*      ? getIn(errors, 'username')*/}
                {/*      : ''*/}
                {/*  }*/}
                {/*/>*/}
                <Input
                  label={'Firstname*'}
                  name={'firstname'}
                  floatLabel
                  value={values.firstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    getIn(errors, 'firstname') && getIn(touched, 'firstname')
                      ? getIn(errors, 'firstname')
                      : ''
                  }
                />
                <Input
                  label={'Lastname*'}
                  name={'lastname'}
                  floatLabel={true}
                  value={values.lastname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    getIn(errors, 'lastname') && getIn(touched, 'lastname')
                      ? getIn(errors, 'lastname')
                      : ''
                  }
                />
                <Input
                  label={'Phone'}
                  type={'tel'}
                  name={'phone'}
                  floatLabel={true}
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    getIn(errors, 'phone') && getIn(touched, 'phone')
                      ? getIn(errors, 'phone')
                      : ''
                  }
                />
                <Input
                  label={'Country*'}
                  name={'addresses.country'}
                  floatLabel
                  value={values.addresses?.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    getIn(errors, 'addresses.country') &&
                    getIn(touched, 'addresses.country')
                      ? getIn(errors, 'addresses.country')
                      : ''
                  }
                />
                <Input
                  label={
                    values.addresses?.country?.trim().toUpperCase() === 'CANADA'
                      ? 'Province'
                      : 'State'
                  }
                  name={'addresses.stateOrProvince'}
                  floatLabel
                  value={values.addresses?.stateOrProvince}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    getIn(errors, 'addresses.stateOrProvince') &&
                    getIn(touched, 'addresses.stateOrProvince')
                      ? getIn(errors, 'addresses.stateOrProvince')
                      : ''
                  }
                />
                <Input
                  label={'City'}
                  name={'addresses.city'}
                  floatLabel
                  value={values.addresses?.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    getIn(errors, 'addresses.city') &&
                    getIn(touched, 'addresses.city')
                      ? getIn(errors, 'addresses.city')
                      : ''
                  }
                />
                <Input
                  label="Street"
                  floatLabel
                  name={`addresses.street`}
                  value={values.addresses?.street}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    getIn(errors, `addresses.street`) &&
                    getIn(touched, `addresses.street`)
                      ? getIn(errors, `addresses.street`)
                      : ''
                  }
                />
                {/*<Input*/}
                {/*  label="House Number"*/}
                {/*  floatLabel*/}
                {/*  name={`addresses.houseNumber`}*/}
                {/*  value={values.addresses?.houseNumber}*/}
                {/*  onChange={handleChange}*/}
                {/*  onBlur={handleBlur}*/}
                {/*  errors={*/}
                {/*    getIn(errors, `addresses.houseNumber`) &&*/}
                {/*    getIn(touched, `addresses.houseNumber`)*/}
                {/*      ? getIn(errors, `addresses.houseNumber`)*/}
                {/*      : ''*/}
                {/*  }*/}
                {/*/>*/}
                {/*<Input*/}
                {/*  label="Apartment Number"*/}
                {/*  floatLabel*/}
                {/*  name={`addresses.apartmentNumber`}*/}
                {/*  value={values.addresses?.apartmentNumber}*/}
                {/*  onChange={handleChange}*/}
                {/*  onBlur={handleBlur}*/}
                {/*  errors={*/}
                {/*    getIn(errors, `addresses.apartmentNumber`) &&*/}
                {/*    getIn(touched, `addresses.apartmentNumber`)*/}
                {/*      ? getIn(errors, `addresses.apartmentNumber`)*/}
                {/*      : ''*/}
                {/*  }*/}
                {/*/>*/}
                <Input
                  label="Postal Code"
                  floatLabel
                  name={`addresses.postalCode`}
                  value={values.addresses?.postalCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    getIn(errors, `addresses.postalCode`) &&
                    getIn(touched, `addresses.postalCode`)
                      ? getIn(errors, `addresses.postalCode`)
                      : ''
                  }
                />
                {/*<FieldArray name="addresses">*/}
                {/*  {({ push, remove }) => (*/}
                {/*    <div className={`${baseClass}--profile-address`}>*/}
                {/*      {(values.addresses || []).map((address, index) => (*/}
                {/*        <div*/}
                {/*          key={index}*/}
                {/*          className={`${baseClass}--profile-address-block`}*/}
                {/*        >*/}
                {/*          <div*/}
                {/*            className={`${baseClass}--profile-address-title`}*/}
                {/*          >*/}
                {/*            Address {index + 1}*/}
                {/*          </div>*/}
                {/*          <Input*/}
                {/*            label="Country*"*/}
                {/*            floatLabel*/}
                {/*            name={`addresses[${index}].country`}*/}
                {/*            value={address.country}*/}
                {/*            onChange={handleChange}*/}
                {/*            onBlur={handleBlur}*/}
                {/*            errors={*/}
                {/*              getIn(errors, `addresses[${index}].country`) &&*/}
                {/*              getIn(touched, `addresses[${index}].country`)*/}
                {/*                ? getIn(errors, `addresses[${index}].country`)*/}
                {/*                : ''*/}
                {/*            }*/}
                {/*          />*/}
                {/*          <Input*/}
                {/*            label="State"*/}
                {/*            floatLabel*/}
                {/*            name={`addresses[${index}].state`}*/}
                {/*            value={address.state}*/}
                {/*            onChange={handleChange}*/}
                {/*            onBlur={handleBlur}*/}
                {/*            errors={*/}
                {/*              getIn(errors, `addresses[${index}].state`) &&*/}
                {/*              getIn(touched, `addresses[${index}].state`)*/}
                {/*                ? getIn(errors, `addresses[${index}].state`)*/}
                {/*                : ''*/}
                {/*            }*/}
                {/*          />*/}
                {/*          <Input*/}
                {/*            label="City*"*/}
                {/*            floatLabel*/}
                {/*            name={`addresses[${index}].city`}*/}
                {/*            value={address.city}*/}
                {/*            onChange={handleChange}*/}
                {/*            onBlur={handleBlur}*/}
                {/*            errors={*/}
                {/*              getIn(errors, `addresses[${index}].city`) &&*/}
                {/*              getIn(touched, `addresses[${index}].city`)*/}
                {/*                ? getIn(errors, `addresses[${index}].city`)*/}
                {/*                : ''*/}
                {/*            }*/}
                {/*          />*/}
                {/*          <Input*/}
                {/*            label="Street*"*/}
                {/*            floatLabel*/}
                {/*            name={`addresses[${index}].street`}*/}
                {/*            value={address.street}*/}
                {/*            onChange={handleChange}*/}
                {/*            onBlur={handleBlur}*/}
                {/*            errors={*/}
                {/*              getIn(errors, `addresses[${index}].street`) &&*/}
                {/*              getIn(touched, `addresses[${index}].street`)*/}
                {/*                ? getIn(errors, `addresses[${index}].street`)*/}
                {/*                : ''*/}
                {/*            }*/}
                {/*          />*/}
                {/*          <Input*/}
                {/*            label="House Number*"*/}
                {/*            floatLabel*/}
                {/*            name={`addresses[${index}].houseNumber`}*/}
                {/*            value={address.houseNumber}*/}
                {/*            onChange={handleChange}*/}
                {/*            onBlur={handleBlur}*/}
                {/*            errors={*/}
                {/*              getIn(*/}
                {/*                errors,*/}
                {/*                `addresses[${index}].houseNumber`,*/}
                {/*              ) &&*/}
                {/*              getIn(touched, `addresses[${index}].houseNumber`)*/}
                {/*                ? getIn(*/}
                {/*                    errors,*/}
                {/*                    `addresses[${index}].houseNumber`,*/}
                {/*                  )*/}
                {/*                : ''*/}
                {/*            }*/}
                {/*          />*/}
                {/*          <Input*/}
                {/*            label="Apartment Number"*/}
                {/*            floatLabel*/}
                {/*            name={`addresses[${index}].apartmentNumber`}*/}
                {/*            value={address.apartmentNumber}*/}
                {/*            onChange={handleChange}*/}
                {/*            onBlur={handleBlur}*/}
                {/*            errors={*/}
                {/*              getIn(*/}
                {/*                errors,*/}
                {/*                `addresses[${index}].apartmentNumber`,*/}
                {/*              ) &&*/}
                {/*              getIn(*/}
                {/*                touched,*/}
                {/*                `addresses[${index}].apartmentNumber`,*/}
                {/*              )*/}
                {/*                ? getIn(*/}
                {/*                    errors,*/}
                {/*                    `addresses[${index}].apartmentNumber`,*/}
                {/*                  )*/}
                {/*                : ''*/}
                {/*            }*/}
                {/*          />*/}
                {/*          <Input*/}
                {/*            label="Zip Code"*/}
                {/*            floatLabel*/}
                {/*            type={'number'}*/}
                {/*            name={`addresses[${index}].zipCode`}*/}
                {/*            value={address.zipCode}*/}
                {/*            onChange={handleChange}*/}
                {/*            onBlur={handleBlur}*/}
                {/*            errors={*/}
                {/*              getIn(errors, `addresses[${index}].zipCode`) &&*/}
                {/*              getIn(touched, `addresses[${index}].zipCode`)*/}
                {/*                ? getIn(errors, `addresses[${index}].zipCode`)*/}
                {/*                : ''*/}
                {/*            }*/}
                {/*          />*/}
                {/*          <Button*/}
                {/*            type="button"*/}
                {/*            theme={'danger'}*/}
                {/*            onClick={() => remove(index)}*/}
                {/*          >*/}
                {/*            Remove Address*/}
                {/*          </Button>*/}
                {/*        </div>*/}
                {/*      ))}*/}

                {/*      <Button*/}
                {/*        type="button"*/}
                {/*        onClick={() =>*/}
                {/*          push({*/}
                {/*            country: '',*/}
                {/*            state: null,*/}
                {/*            city: '',*/}
                {/*            street: '',*/}
                {/*            houseNumber: '',*/}
                {/*            apartmentNumber: '',*/}
                {/*            zipCode: null,*/}
                {/*            latitude: null,*/}
                {/*            longitude: null,*/}
                {/*            phone: null,*/}
                {/*          })*/}
                {/*        }*/}
                {/*      >*/}
                {/*        Add Address*/}
                {/*      </Button>*/}
                {/*    </div>*/}
                {/*  )}*/}
                {/*</FieldArray>*/}

                <div className={`${baseClass}--profile-btns`}>
                  <Button
                    type={'submit'}
                    isDisabled={isSubmitting || isLoading}
                  >
                    Save
                  </Button>
                  <Button type={'reset'}>Reset</Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProfilePage;
