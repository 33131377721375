import React, { FC, useEffect, useRef, useState } from 'react';
import './SkeletonLoader.scss';

const baseClass = 'SkeletonLoader';

interface SkeletonProps {
  children?: React.ReactNode;
  isLoading: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const Skeleton: FC<SkeletonProps> = ({
  children,
  isLoading,
  className,
  style,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const [parentFontSize, setParentFontSize] = useState<string>('inherit');

  useEffect(() => {
    if (parentRef.current) {
      // Access the computed styles of the parent element
      const computedStyle = window.getComputedStyle(parentRef.current);
      const fontSize = computedStyle.fontSize;
      setParentFontSize(fontSize);
    }
  }, [isLoading]);

  if (!isLoading) {
    return <>{children}</>;
  }

  // Проверка на то, что children — это элемент React
  const childElement = React.isValidElement(children) ? children : null;
  const isStringOrNumberChild =
    typeof children === 'string' || typeof children === 'number';

  if (isStringOrNumberChild || !childElement) {
    return (
      <span
        ref={parentRef}
        className={`${baseClass} ${className || ''}`}
        style={{ fontSize: parentFontSize, ...style }}
      >
        {/* Визуальное представление скелетона */}
        <span className={`${baseClass}__placeholder`}>Loading...</span>
      </span>
    );
  }

  const childClassName =
    (childElement?.props as { className?: string })?.className || '';

  const childTagName =
    typeof childElement.type === 'string' ? childElement.type : 'span'; // Устанавливаем как span по умолчанию для функциональных компонентов

  // Условно передаем ref только HTML-элементам
  const elementProps: React.HTMLAttributes<HTMLElement> & {
    ref?: React.Ref<HTMLDivElement>;
  } = {
    className: `${baseClass} ${childClassName} ${className || ''}`,
    style: { fontSize: parentFontSize, ...style },
  };

  if (typeof childElement.type === 'string') {
    elementProps.ref = parentRef;
  }

  return React.createElement(
    childTagName,
    elementProps,
    <span className={`${baseClass}__placeholder`}>Loading...</span>,
  );
};

export default Skeleton;
