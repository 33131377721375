import React, { FC, useEffect } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchingProfile,
  profileSelector,
} from '@/store/profile/profileSlices';
import Button from '@/components/Button';
import logo from '@/img/propsavvy-logo-with-text.png';
import './Navbar.scss';

const baseClass = 'navbar';

interface NavbarItemProps {
  to: string;
  icon?: React.ReactNode | string | null;
  children: React.ReactNode;
}

const NavbarItem: FC<NavbarItemProps> = ({ to, icon, children }) => {
  return (
    <li className={`${baseClass}-menu--list-item`}>
      <NavLink
        to={to}
        className={({ isActive }) => {
          return isActive
            ? `${baseClass}-menu--list-item-link active`
            : `${baseClass}-menu--list-item-link`;
        }}
      >
        {icon && (
          <span className={`${baseClass}-menu--list-item-link-icon`}>
            {icon}
          </span>
        )}
        <span className={`${baseClass}-menu--list-item-link-text`}>
          {children}
        </span>
      </NavLink>
    </li>
  );
};

const Navbar = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const profile = useSelector(profileSelector);
  const [searchValue, setSearchValue] = React.useState('');

  useEffect(() => {
    dispatch(fetchingProfile());
  }, [dispatch]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className={baseClass}>
      <div className={`${baseClass}-menu`}>
        <div className={`${baseClass}-logo`}>
          <img className={`${baseClass}-logo-img`} src={logo} alt="logo" />
        </div>
        <ul className={`${baseClass}-menu--list`}>
          <NavbarItem
            to={'/'}
            icon={<i className="fa-solid fa-border-all"></i>}
          >
            <span>Dashboard</span>
          </NavbarItem>
          <NavbarItem
            to={'/properties'}
            icon={<i className="fa-solid fa-house"></i>}
          >
            Properties
          </NavbarItem>
          <NavbarItem
            to={'/transactions'}
            icon={<i className="fa-solid fa-arrow-right-arrow-left"></i>}
          >
            Transactions
          </NavbarItem>
          <NavbarItem
            to={'/chats'}
            icon={<i className="fa-regular fa-comments"></i>}
          >
            Chats
          </NavbarItem>
          <NavbarItem
            to={'/calendar'}
            icon={<i className="fa-solid fa-calendar-days"></i>}
          >
            Calendar
          </NavbarItem>
          <NavbarItem
            to={'/profile'}
            icon={<i className="fa-regular fa-user"></i>}
          >
            Account
          </NavbarItem>
        </ul>
      </div>
      <div className={`${baseClass}-view`}>
        <div className={`${baseClass}-top-menu`}>
          {/*<Input*/}
          {/*  value={searchValue}*/}
          {/*  onChange={onChange}*/}
          {/*  className={`${baseClass}-search`}*/}
          {/*  placeholder={'Search'}*/}
          {/*/>*/}
          <div>
            {profile?.firstname} {profile?.lastname}
          </div>
          <div className={`${baseClass}-top-menu-right`}>
            <Button onClick={auth.logout}>Logout</Button>
          </div>
        </div>
        <div className={`${baseClass}-content`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
