import React, { useEffect, useState } from 'react';
import PropertyOverviewCard from '@/components/PropertyOverviewCard';
import { useNavigate, useParams } from 'react-router-dom';
import { IProperty, IUnit, PaymentMethod, PaymentPeriod } from '@/types';
import { useSelector } from 'react-redux';
import { profileSelector } from '@/store/profile/profileSlices';
import { createUnitRequest, getPropertyRequest } from '@/api';
import Card from '@/components/Card';
import Button from '@/components/Button';
import getAxiosErrors from '@/utils/getAxiosErrors';
import { Field, FieldArray, Form, Formik, getIn } from 'formik';
import './AddUnitPage.scss';
import Input from '@/components/Input';
import FileUploader from '@/components/FileUploader';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import 'moment/locale/ca';
import moment from 'moment';

const baseClass = 'AddUnitPage';

const unitInfoInitialState: IUnit = {
  number: '', // ---Unit information---
  floor: null,
  rooms: null,
  dimension: null,
  bedrooms: null,
  numberOfFloors: null,
  bathrooms: null,
  roomsList: [
    {
      type: null,
      floor: null,
      flooring: null,
      dimension: null,
    },
  ],
  informOtherChecked: false,
  informOtherSpecify: '',
  outdoorParking: false, // ---Inclusions---
  outdoorParkingNumbers: null,
  outdoorParkingSpaces: null,
  indoorParking: false,
  indoorParkingNumbers: null,
  indoorParkingSpaces: null,
  lockerOrStorage: false,
  lockerOrStorageSpecify: '',
  otherAccessoriesAndDependencies: false,
  otherAccessoriesAndDependenciesSpecify: '',
  furnitureIsIncluded: false,
  stove: false,
  dishwasher: false,
  washer: false,
  microwave: false,
  refrigerator: false,
  dryer: false,
  isTables: false,
  tablesNumber: null,
  isChestOfDrawers: false,
  chestOfDrawersNumber: null,
  isChairs: false,
  chairsNumber: null,
  isCouches: false,
  couchesNumber: null,
  isArmchairs: false,
  armchairsNumber: null,
  isBeds: false,
  bedsNumber: null,
  bedsSize: null,
  isInclusionsOther: false,
  inclusionsOtherSpecify: '',
  inviteEmail: null, // ---Link tenant---
  documents: [], // ---Documents---
  isFixedTermsLease: false, // ---Term of lease---
  termLeasePeriod: null,
  termLeaseStartDate: null,
  termLeaseEndDate: null,
  isIndeterminateTermLease: false,
  indeterminateTermLeaseStartDate: null,
  rentPrice: null, // ---Rent---
  rentPricePeriod: PaymentPeriod.MONTH,
  costOfServices: null,
  costOfServicesPeriod: PaymentPeriod.MONTH,
  totalRentPrice: null,
  totalRentPricePeriod: PaymentPeriod.MONTH,
  rentPriceDescription: '',
  firstPaymentDate: null,
  paymentFirstDay: PaymentPeriod.MONTH,
  paymentMethod: PaymentMethod.CASH,
  paymentMethodOtherSpecify: '',
};

const AddUnitPage = () => {
  const { propertyId } = useParams<{ propertyId: string }>();
  const navigate = useNavigate();
  const [property, setProperty] = useState<IProperty | null>(null);
  const [isPropertyLoading, setIsPropertyLoading] = useState(false);
  const profile = useSelector(profileSelector);
  const numericId = Number(propertyId);
  const isNumeric = !isNaN(numericId);

  useEffect(() => {
    setIsPropertyLoading(true);
    getPropertyRequest(numericId)
      .then((res) => {
        setProperty(res.data);
      })
      .catch((err) => {
        const errs = getAxiosErrors(err);
        console.debug(errs);
      })
      .finally(() => {
        setIsPropertyLoading(false);
      });
  }, [numericId]);

  if (!isNumeric || (!isPropertyLoading && !property)) {
    return (
      <Card className={baseClass}>
        <h4>Invalid property ID</h4>
        <Button onClick={() => navigate('/properties')}>
          Go back to properties
        </Button>
      </Card>
    );
  }

  return (
    <div className={baseClass}>
      <Card.Header className={`${baseClass}--header`}>
        <span className={`${baseClass}--header-text`}>
          <span className={`${baseClass}--header-text-prop`}>
            Add unit to property
          </span>
        </span>
        <Button
          className={`${baseClass}--header-btn`}
          theme={'secondary'}
          onClick={() => {
            navigate(`/properties/${propertyId}`);
          }}
        >
          Back to property
        </Button>
      </Card.Header>
      <PropertyOverviewCard
        property={property}
        navButton={
          <Button
            className={`${baseClass}--overview-btn`}
            theme={'blue'}
            onClick={() => {
              navigate('/properties/' + property?.id);
            }}
          >
            <span className={`${baseClass}--overview-btn-content`}>
              <i className="fa-solid fa-circle-info"></i>
              Property information
            </span>
          </Button>
        }
      />
      <Card className={`${baseClass}--information`}>
        <Card.Header className={`${baseClass}--information-header`}>
          <Card.Title className={`${baseClass}--information-title`}>
            Unit information
          </Card.Title>
        </Card.Header>
        <Card.Body className={`${baseClass}--information-body`}>
          <Card.Container className={`${baseClass}--information-container`}>
            <Formik
              initialValues={unitInfoInitialState}
              onSubmit={(values, { setErrors }) => {
                const { roomsList, ...rest } = values;
                const roomsArr = (roomsList || []).filter((item) => {
                  return (
                    item.type !== null &&
                    item.floor !== null &&
                    item.dimension !== null &&
                    item.flooring !== null
                  );
                });
                const data = {
                  ...rest,
                  roomsList: roomsArr,
                };
                console.log(data);

                createUnitRequest(Number(propertyId), data)
                  .then((res) => {
                    // navigate(`/properties/${propertyId}/units/${res.data.id}`);
                  })
                  .catch((err) => setErrors(getAxiosErrors(err)))
                  .finally();
              }}
            >
              {({
                values,
                errors,
                handleBlur,
                touched,
                setFieldValue,
                handleChange,
                isSubmitting,
                dirty,
              }) => (
                <Form className={`${baseClass}--information-form`}>
                  <div className={`${baseClass}--information-form--unit`}>
                    <div
                      className={`${baseClass}--information-form--unit-item ${baseClass}--information-form--unit-item-label`}
                    >
                      <div
                        className={`fw-600 fs-14 ${baseClass}--information-form--unit-item-label-content`}
                      >
                        Unit number
                      </div>
                    </div>
                    <Input
                      className={`${baseClass}--information-form--unit-item ${baseClass}--information-form--unit-item-input`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={'Unit number'}
                      value={values.number}
                      name={'number'}
                      errors={
                        errors.number && touched.number ? errors.number : ''
                      }
                    />
                  </div>
                  <div className={`${baseClass}--information-form--top`}>
                    <div
                      className={`${baseClass}--information-form--top-item ${baseClass}--information-form--top-item-label`}
                    >
                      <div
                        className={`fw-600 fs-14 ${baseClass}--information-form--top-item-label-content fw-600`}
                      >
                        Floor
                      </div>
                    </div>
                    <Input
                      className={`${baseClass}--information-form--top-item ${baseClass}--information-form--top-item-input`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={'Floor'}
                      value={values.floor}
                      name={'floor'}
                      errors={errors.floor && touched.floor ? errors.floor : ''}
                    />
                    <div
                      className={`${baseClass}--information-form--top-item ${baseClass}--information-form--top-item-label`}
                    >
                      <div
                        className={`fw-600 fs-14 ${baseClass}--information-form--top-item-label-content fw-600`}
                      >
                        Rooms
                      </div>
                    </div>
                    <Input
                      className={`${baseClass}--information-form--top-item ${baseClass}--information-form--top-item-input`}
                      onChange={handleChange}
                      type={'number'}
                      onBlur={handleBlur}
                      placeholder={'Rooms'}
                      value={values.rooms}
                      name={'rooms'}
                      errors={errors.rooms && touched.rooms ? errors.rooms : ''}
                    />
                    <div
                      className={`${baseClass}--information-form--top-item ${baseClass}--information-form--top-item-label`}
                    >
                      <div
                        className={`fw-600 fs-14 ${baseClass}--information-form--top-item-label-content fw-600`}
                      >
                        Dimension
                      </div>
                    </div>
                    <Input
                      className={`${baseClass}--information-form--top-item ${baseClass}--information-form--top-item-input`}
                      onChange={handleChange}
                      type={'number'}
                      onBlur={handleBlur}
                      placeholder={'Dimension'}
                      value={values.dimension}
                      name={'dimension'}
                      errors={
                        errors.dimension && touched.dimension
                          ? errors.dimension
                          : ''
                      }
                    />
                    <div
                      className={`${baseClass}--information-form--top-item ${baseClass}--information-form--top-item-label`}
                    >
                      <div
                        className={`fw-600 fs-14 ${baseClass}--information-form--top-item-label-content fw-600`}
                      >
                        Bedrooms
                      </div>
                    </div>
                    <Input
                      className={`${baseClass}--information-form--top-item ${baseClass}--information-form--top-item-input`}
                      onChange={handleChange}
                      type={'number'}
                      onBlur={handleBlur}
                      placeholder={'Bedrooms'}
                      value={values.bedrooms}
                      name={'bedrooms'}
                      errors={
                        errors.bedrooms && touched.bedrooms
                          ? errors.bedrooms
                          : ''
                      }
                    />
                    <div
                      className={`${baseClass}--information-form--top-item ${baseClass}--information-form--top-item-label`}
                    >
                      <div
                        className={`fw-600 fs-14 ${baseClass}--information-form--top-item-label-content fw-600`}
                      >
                        Number of floors
                      </div>
                    </div>
                    <Input
                      className={`${baseClass}--information-form--top-item ${baseClass}--information-form--top-item-input`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={'Number of floors'}
                      value={values.numberOfFloors}
                      name={'numberOfFloors'}
                      errors={
                        errors.numberOfFloors && touched.numberOfFloors
                          ? errors.numberOfFloors
                          : ''
                      }
                    />
                    <div
                      className={`${baseClass}--information-form--top-item ${baseClass}--information-form--top-item-label`}
                    >
                      <div
                        className={`fw-600 fs-14 ${baseClass}--information-form--top-item-label-content fw-600`}
                      >
                        Bathrooms
                      </div>
                    </div>
                    <Input
                      className={`${baseClass}--information-form--top-item ${baseClass}--information-form--top-item-input`}
                      onChange={handleChange}
                      type={'number'}
                      onBlur={handleBlur}
                      placeholder={'Bathrooms'}
                      value={values.bathrooms}
                      name={'bathrooms'}
                      errors={
                        errors.bathrooms && touched.bathrooms
                          ? errors.bathrooms
                          : ''
                      }
                    />
                  </div>
                  <div className={`${baseClass}--information-form--middle`}>
                    <FieldArray name={'roomsList'}>
                      {({ push, remove }) => (
                        <>
                          {(values.roomsList || []).map((item, index, arr) => (
                            <div
                              key={index}
                              className={`${baseClass}--information-form--middle-row`}
                            >
                              <span
                                className={`fw-600 fs-14 ${baseClass}--information-form--middle-row-title`}
                              >
                                Room
                              </span>
                              <Input
                                className={`${baseClass}--information-form--middle-row-type`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder={'Type'}
                                name={`roomsList.${index}.type`}
                                value={item.type}
                                errors={
                                  getIn(errors, `items.${index}.number`) &&
                                  getIn(touched, `items.${index}.number`)
                                    ? getIn(errors, `items.${index}.number`)
                                    : ''
                                }
                              />
                              <Input
                                className={`${baseClass}--information-form--middle-row-floor`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder={'Floor'}
                                name={`roomsList.${index}.floor`}
                                value={item.floor}
                                errors={
                                  getIn(errors, `items.${index}.floor`) &&
                                  getIn(touched, `items.${index}.floor`)
                                    ? getIn(errors, `items.${index}.floor`)
                                    : ''
                                }
                              />
                              <Input
                                className={`${baseClass}--information-form--middle-row-flooring`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder={'Flooring'}
                                name={`roomsList.${index}.flooring`}
                                value={item.flooring}
                                errors={
                                  getIn(errors, `items.${index}.flooring`) &&
                                  getIn(touched, `items.${index}.flooring`)
                                    ? getIn(errors, `items.${index}.flooring`)
                                    : ''
                                }
                              />
                              <Input
                                className={`${baseClass}--information-form--middle-row-dimension`}
                                onChange={handleChange}
                                type={'number'}
                                onBlur={handleBlur}
                                placeholder={'Dimension'}
                                name={`roomsList.${index}.dimension`}
                                value={item.dimension}
                                errors={
                                  getIn(errors, `items.${index}.dimension`) &&
                                  getIn(touched, `items.${index}.dimension`)
                                    ? getIn(errors, `items.${index}.dimension`)
                                    : ''
                                }
                              />
                              {index === arr.length - 1 ? (
                                <Button
                                  type="button"
                                  theme={'secondary'}
                                  className={`${baseClass}--information-form--middle-row-btn`}
                                  onClick={() =>
                                    push({
                                      type: null,
                                      floor: null,
                                      flooring: null,
                                      dimension: null,
                                    })
                                  } // Добавление нового поля
                                >
                                  <i className="fa-solid fa-plus"></i>
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  className={`${baseClass}--information-form--middle-row-btn`}
                                  theme={'secondary'}
                                  onClick={() => remove(index)}
                                >
                                  <i className="fa-solid fa-minus"></i>
                                </Button>
                              )}
                            </div>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </div>
                  <div className={`${baseClass}--information-form--bottom`}>
                    <label
                      className={`fw-600 fs-14  ${baseClass}--information-form--bottom-other`}
                    >
                      <Field
                        type="checkbox"
                        className={`${baseClass}--information-form--bottom-other-checkbox`}
                        name={'informOtherChecked'}
                      />
                      <span>Other</span>
                    </label>
                    <Field
                      className={`${baseClass}--information-form--bottom-other-textarea`}
                      as="textarea"
                      placeholder={'Specify'}
                      name={'informOtherSpecify'}
                    />
                  </div>
                  <div className={`${baseClass}--information-form--btns`}>
                    <Button type={'submit'}>Save</Button>
                    <Button type={'reset'} theme={'secondary'}>
                      Clear
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Container>
        </Card.Body>
      </Card>
      <Card className={`${baseClass}--inclusions`}>
        <Card.Header className={`${baseClass}--inclusions-header`}>
          <Card.Title className={`${baseClass}--inclusions-title`}>
            Inclusions
          </Card.Title>
          <Card.TitleDescription
            className={`${baseClass}--inclusions-title-description`}
          >
            Description of leased dwelling, accessories and dependencies.
          </Card.TitleDescription>
        </Card.Header>
        <Card.Body className={`${baseClass}--inclusions-body`}>
          <Card.Container className={`${baseClass}--inclusions-container`}>
            <Formik
              initialValues={unitInfoInitialState}
              onSubmit={(values, { setErrors }) => {
                const { roomsList, ...rest } = values;
                const roomsArr = (roomsList || []).filter((item) => {
                  return (
                    item.type !== null &&
                    item.floor !== null &&
                    item.dimension !== null &&
                    item.flooring !== null
                  );
                });
                const data = {
                  ...rest,
                  roomsList: roomsArr,
                };
                console.log(data);

                createUnitRequest(Number(propertyId), data)
                  .then((res) => {
                    // navigate(`/properties/${propertyId}/units/${res.data.id}`);
                  })
                  .catch((err) => setErrors(getAxiosErrors(err)))
                  .finally();
              }}
            >
              {({
                values,
                errors,
                handleBlur,
                touched,
                setFieldValue,
                handleChange,
                isSubmitting,
                dirty,
              }) => (
                <Form className={`${baseClass}--inclusions-form`}>
                  <div className={`${baseClass}--inclusions-parking`}>
                    <div className={`${baseClass}--inclusions-parking-item`}>
                      <label
                        className={`fw-600 fs-14 ${baseClass}--inclusions-parking-item-checkbox`}
                      >
                        <Field
                          type="checkbox"
                          name={'outdoorParking'}
                          className={`${baseClass}--inclusions-parking-item-checkbox-check`}
                        />
                        Outdoor parking
                      </label>
                      <div
                        className={`${baseClass}--inclusions-parking-item-grid`}
                      >
                        <div
                          className={`fs-14 ${baseClass}--input-label  ${baseClass}--inclusions-parking-item-grid-label`}
                        >
                          Number of places
                        </div>
                        <Input
                          className={`${baseClass}--input ${baseClass}--inclusions-parking-item-grid-input`}
                          onChange={handleChange}
                          type={'number'}
                          onBlur={handleBlur}
                          value={values.outdoorParkingNumbers}
                          name={'outdoorParkingNumbers'}
                          errors={
                            errors.outdoorParkingNumbers &&
                            touched.outdoorParkingNumbers
                              ? errors.outdoorParkingNumbers
                              : ''
                          }
                        />
                        <div
                          className={`fs-14 ${baseClass}--input-label ${baseClass}--inclusions-parking-item-grid-label`}
                        >
                          Parking places
                        </div>
                        <Input
                          className={`${baseClass}--input ${baseClass}--inclusions-parking-item-input`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.outdoorParkingSpaces}
                          name={'outdoorParkingSpaces'}
                          errors={
                            errors.outdoorParkingSpaces &&
                            touched.outdoorParkingSpaces
                              ? errors.outdoorParkingSpaces
                              : ''
                          }
                        />
                      </div>
                    </div>
                    <div className={`${baseClass}--inclusions-parking-item`}>
                      <label
                        className={`fw-600 fs-14 ${baseClass}--inclusions-parking-item-checkbox`}
                      >
                        <Field
                          type="checkbox"
                          name={'indoorParking'}
                          className={`${baseClass}--inclusions-parking-item-checkbox-check`}
                        />
                        Indoor parking
                      </label>
                      <div
                        className={`${baseClass}--inclusions-parking-item-grid`}
                      >
                        <div
                          className={`fs-14 ${baseClass}--input-label  ${baseClass}--inclusions-parking-item-grid-label`}
                        >
                          Number of places
                        </div>
                        <Input
                          className={`${baseClass}--input ${baseClass}--inclusions-parking-item-grid-input`}
                          onChange={handleChange}
                          type={'number'}
                          onBlur={handleBlur}
                          value={values.indoorParkingNumbers}
                          name={'indoorParkingNumbers'}
                          errors={
                            errors.outdoorParkingNumbers &&
                            touched.outdoorParkingNumbers
                              ? errors.outdoorParkingNumbers
                              : ''
                          }
                        />
                        <div
                          className={`fs-14 ${baseClass}--input-label ${baseClass}--inclusions-parking-item-grid-label`}
                        >
                          Parking places
                        </div>
                        <Input
                          className={`${baseClass}--input ${baseClass}--inclusions-parking-item-input`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.indoorParkingSpaces}
                          name={'indoorParkingSpaces'}
                          errors={
                            errors.indoorParkingSpaces &&
                            touched.indoorParkingSpaces
                              ? errors.indoorParkingSpaces
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${baseClass}--inclusions-locker`}>
                    <div className={`${baseClass}--inclusions-locker-item`}>
                      <label
                        className={`fw-600 fs-14 ${baseClass}--inclusions-locker-item-checkbox`}
                      >
                        <Field
                          type="checkbox"
                          name={'lockerOrStorage'}
                          className={`${baseClass}--inclusions-locker-item-checkbox-check`}
                        />
                        Locker or storage space
                      </label>
                      <Field
                        className={`${baseClass}--inclusions-locker-item-textarea`}
                        as="textarea"
                        placeholder={'Specify'}
                        name={'lockerOrStorageSpecify'}
                      />
                    </div>
                    <div className={`${baseClass}--inclusions-locker-item`}>
                      <label
                        className={`fw-600 fs-14 ${baseClass}--inclusions-locker-item-checkbox`}
                      >
                        <Field
                          type="checkbox"
                          name={'otherAccessoriesAndDependencies'}
                          className={`${baseClass}--inclusions-locker-item-checkbox-check`}
                        />
                        Other accessories and dependencies
                      </label>
                      <Field
                        className={`${baseClass}--inclusions-locker-item-textarea`}
                        as="textarea"
                        placeholder={'Specify'}
                        name={'otherAccessoriesAndDependenciesSpecify'}
                      />
                    </div>
                  </div>
                  <label
                    className={`fw-600 fs-14 ${baseClass}--inclusions-furnitureLeased`}
                  >
                    Furniture is leased and included in the rent:
                    <Field
                      type="checkbox"
                      name={'furnitureIsIncluded'}
                      className={`${baseClass}--inclusions-furnitureLeased-checkbox`}
                    />
                  </label>
                  <div className={`${baseClass}--inclusions-appliances`}>
                    <div
                      className={`fw-600 fs-14 ${baseClass}--inclusions-appliances-title`}
                    >
                      Appliances
                    </div>
                    <div
                      className={`${baseClass}--inclusions-appliances-items`}
                    >
                      <label
                        className={`fs-14 ${baseClass}--inclusions-appliances-items-item`}
                      >
                        <Field
                          type="checkbox"
                          name={'stove'}
                          className={`${baseClass}--inclusions-appliances-items-item-checkbox`}
                        />
                        Stove
                      </label>
                      <label
                        className={`fs-14 ${baseClass}--inclusions-appliances-items-item`}
                      >
                        <Field
                          type="checkbox"
                          name={'dishwasher'}
                          className={`${baseClass}--inclusions-appliances-items-item-checkbox`}
                        />
                        Dishwasher
                      </label>
                      <label
                        className={`fs-14 ${baseClass}--inclusions-appliances-items-item`}
                      >
                        <Field
                          type="checkbox"
                          name={'washer'}
                          className={`${baseClass}--inclusions-appliances-items-item-checkbox`}
                        />
                        Washer
                      </label>
                      <label
                        className={`fs-14 ${baseClass}--inclusions-appliances-items-item`}
                      >
                        <Field
                          type="checkbox"
                          name={'microwave'}
                          className={`${baseClass}--inclusions-appliances-items-item-checkbox`}
                        />
                        Microwave oven
                      </label>
                      <label
                        className={`fs-14 ${baseClass}--inclusions-appliances-items-item`}
                      >
                        <Field
                          type="checkbox"
                          name={'refrigerator'}
                          className={`${baseClass}--inclusions-appliances-items-item-checkbox`}
                        />
                        Refrigerator
                      </label>
                      <label
                        className={`fs-14 ${baseClass}--inclusions-appliances-items-item`}
                      >
                        <Field
                          type="checkbox"
                          name={'dryer'}
                          className={`${baseClass}--inclusions-appliances-items-item-checkbox`}
                        />
                        Dryer
                      </label>
                    </div>
                  </div>
                  <div className={`${baseClass}--inclusions-furniture`}>
                    <div
                      className={`fw-600 fs-14 ${baseClass}--inclusions-furniture-title`}
                    >
                      Furniture
                    </div>
                    <div className={`${baseClass}--inclusions-furniture-items`}>
                      <div
                        className={`${baseClass}--inclusions-furniture-items-item`}
                      >
                        <label
                          className={`fs-14 ${baseClass}--input-label ${baseClass}--inclusions-furniture-items-item-checkbox`}
                        >
                          <Field
                            type="checkbox"
                            name={'isTables'}
                            className={`${baseClass}--inclusions-furniture-items-item-checkbox-check`}
                          />
                          Table(s)
                        </label>
                        <Input
                          className={`${baseClass}--input ${baseClass}--inclusions-furniture-items-item-input`}
                          onChange={handleChange}
                          type={'number'}
                          onBlur={handleBlur}
                          placeholder={'Number'}
                          value={values.tablesNumber}
                          name={'tablesNumber'}
                          errors={
                            errors.tablesNumber && touched.tablesNumber
                              ? errors.tablesNumber
                              : ''
                          }
                        />
                      </div>
                      <div
                        className={`${baseClass}--inclusions-furniture-items-item`}
                      >
                        <label
                          className={`fs-14 ${baseClass}--input-label ${baseClass}--inclusions-furniture-items-item-checkbox`}
                        >
                          <Field
                            type="checkbox"
                            name={'isChestOfDrawers'}
                            className={`${baseClass}--inclusions-furniture-items-item-checkbox-check`}
                          />
                          Chest(s) of drawers
                        </label>
                        <Input
                          className={`${baseClass}--input ${baseClass}--inclusions-furniture-items-item-input`}
                          onChange={handleChange}
                          type={'number'}
                          onBlur={handleBlur}
                          placeholder={'Number'}
                          value={values.chestOfDrawersNumber}
                          name={'chestOfDrawersNumber'}
                          errors={
                            errors.chestOfDrawersNumber &&
                            touched.chestOfDrawersNumber
                              ? errors.chestOfDrawersNumber
                              : ''
                          }
                        />
                      </div>
                      <div
                        className={`${baseClass}--inclusions-furniture-items-item`}
                      >
                        <label
                          className={`fs-14 ${baseClass}--input-label ${baseClass}--inclusions-furniture-items-item-checkbox`}
                        >
                          <Field
                            type="checkbox"
                            name={'isChairs'}
                            className={`${baseClass}--inclusions-furniture-items-item-checkbox-check`}
                          />
                          Chair(s)
                        </label>
                        <Input
                          className={`${baseClass}--input ${baseClass}--inclusions-furniture-items-item-input`}
                          onChange={handleChange}
                          type={'number'}
                          onBlur={handleBlur}
                          placeholder={'Number'}
                          value={values.chairsNumber}
                          name={'chairsNumber'}
                          errors={
                            errors.chairsNumber && touched.chairsNumber
                              ? errors.chairsNumber
                              : ''
                          }
                        />
                      </div>
                      <div
                        className={`${baseClass}--inclusions-furniture-items-item`}
                      >
                        <label
                          className={`fs-14 ${baseClass}--input-label ${baseClass}--inclusions-furniture-items-item-checkbox`}
                        >
                          <Field
                            type="checkbox"
                            name={'isCouches'}
                            className={`${baseClass}--inclusions-furniture-items-item-checkbox-check`}
                          />
                          Couch(es)
                        </label>
                        <Input
                          className={`${baseClass}--input ${baseClass}--inclusions-furniture-items-item-input`}
                          onChange={handleChange}
                          type={'number'}
                          onBlur={handleBlur}
                          placeholder={'Number'}
                          value={values.couchesNumber}
                          name={'couchesNumber'}
                          errors={
                            errors.couchesNumber && touched.couchesNumber
                              ? errors.couchesNumber
                              : ''
                          }
                        />
                      </div>
                      <div
                        className={`${baseClass}--inclusions-furniture-items-item`}
                      >
                        <label
                          className={`fs-14 ${baseClass}--input-label ${baseClass}--inclusions-furniture-items-item-checkbox`}
                        >
                          <Field
                            type="checkbox"
                            name={'isArmchairs'}
                            className={`${baseClass}--inclusions-furniture-items-item-checkbox-check`}
                          />
                          Armchair(s)
                        </label>
                        <Input
                          className={`${baseClass}--input ${baseClass}--inclusions-furniture-items-item-input`}
                          onChange={handleChange}
                          type={'number'}
                          onBlur={handleBlur}
                          placeholder={'Number'}
                          value={values.armchairsNumber}
                          name={'armchairsNumber'}
                          errors={
                            errors.armchairsNumber && touched.armchairsNumber
                              ? errors.armchairsNumber
                              : ''
                          }
                        />
                      </div>
                      <div
                        className={`${baseClass}--inclusions-furniture-items-item`}
                      >
                        <label
                          className={`fs-14 ${baseClass}--input-label ${baseClass}--inclusions-furniture-items-item-checkbox`}
                        >
                          <Field
                            type="checkbox"
                            name={'isBeds'}
                            className={`${baseClass}--inclusions-furniture-items-item-checkbox-check`}
                          />
                          Bed(s)
                        </label>
                        <Input
                          className={`${baseClass}--input ${baseClass}--inclusions-furniture-items-item-input`}
                          onChange={handleChange}
                          type={'number'}
                          onBlur={handleBlur}
                          placeholder={'Number'}
                          value={values.bedsNumber}
                          name={'bedsNumber'}
                          errors={
                            errors.bedsNumber && touched.bedsNumber
                              ? errors.bedsNumber
                              : ''
                          }
                        />
                        <Input
                          className={`${baseClass}--input ${baseClass}--inclusions-furniture-items-item-input`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={'Size'}
                          value={values.bedsSize}
                          name={'bedsSize'}
                          errors={
                            errors.bedsSize && touched.bedsSize
                              ? errors.bedsSize
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${baseClass}--inclusions-other`}>
                    <label
                      className={`fw-600 fs-14 ${baseClass}--inclusions-other-checkbox`}
                    >
                      <Field
                        type="checkbox"
                        name={'isInclusionsOther'}
                        className={`${baseClass}--inclusions-other-checkbox-check`}
                      />
                      Other
                    </label>
                    <Field
                      className={`${baseClass}--inclusions-other-textarea`}
                      as="textarea"
                      placeholder={'Specify'}
                      name={'inclusionsOtherSpecify'}
                    />
                  </div>
                  <div className={`${baseClass}--inclusions-btns`}>
                    <Button type={'submit'}>Save</Button>
                    <Button type={'reset'} theme={'secondary'}>
                      Clear
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Container>
        </Card.Body>
      </Card>
      <Card className={`${baseClass}--tenant`}>
        <Card.Header className={`${baseClass}--tenant-header`}>
          <Card.Title className={`${baseClass}--tenant-title`}>
            Link tenant
          </Card.Title>
        </Card.Header>
        <Card.Body className={`${baseClass}--tenant-body`}>
          <Card.Container className={`${baseClass}--tenant-container`}>
            <Formik
              initialValues={unitInfoInitialState}
              onSubmit={(values, { setErrors }) => {
                const { roomsList, ...rest } = values;
                const roomsArr = (roomsList || []).filter((item) => {
                  return (
                    item.type !== null &&
                    item.floor !== null &&
                    item.dimension !== null &&
                    item.flooring !== null
                  );
                });
                const data = {
                  ...rest,
                  roomsList: roomsArr,
                };
                console.log(data);

                createUnitRequest(Number(propertyId), data)
                  .then((res) => {
                    // navigate(`/properties/${propertyId}/units/${res.data.id}`);
                  })
                  .catch((err) => setErrors(getAxiosErrors(err)))
                  .finally();
              }}
            >
              {({
                values,
                errors,
                handleBlur,
                touched,
                setFieldValue,
                handleChange,
                isSubmitting,
                dirty,
              }) => (
                <Form className={`${baseClass}--tenant-form`}>
                  <Input
                    className={`${baseClass}--tenant-form-input`}
                    type={'email'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    floatLabel
                    label={'Enter email'}
                    value={values.inviteEmail}
                    name={'inviteEmail'}
                    errors={
                      errors.inviteEmail && touched.inviteEmail
                        ? errors.inviteEmail
                        : ''
                    }
                  />
                  <div className={`${baseClass}--tenant-form-btns`}>
                    <Button type={'submit'}>
                      <i className="fa-solid fa-link"></i> Link tenant
                    </Button>
                    <Button type={'reset'} theme={'secondary'}>
                      Clear
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Container>
        </Card.Body>
      </Card>
      <Card className={`${baseClass}--documents`}>
        <Card.Header className={`${baseClass}--documents-header`}>
          <Card.Title className={`${baseClass}--documents-title`}>
            Documents
          </Card.Title>
        </Card.Header>
        <Card.Body className={`${baseClass}--documents-body`}>
          <Card.Container className={`${baseClass}--documents-container`}>
            <FileUploader
              onFilesSelect={(files) => {
                console.debug(files);
              }}
              files={[]}
              filesDescription={'DOC, PDF, JPG, PNG / max 10 MB'}
              filesLimit={10}
            />
          </Card.Container>
        </Card.Body>
      </Card>
      <Card className={`${baseClass}--rent`}>
        <Card.Header className={`${baseClass}--rent-header`}>
          <Card.Title className={`${baseClass}--rent-title`}>Rent</Card.Title>
        </Card.Header>
        <Card.Body className={`${baseClass}--rent-body`}>
          <Card.Container className={`${baseClass}--rent-container`}>
            <Formik
              initialValues={unitInfoInitialState}
              onSubmit={(values, { setErrors }) => {
                const { roomsList, ...rest } = values;
                const roomsArr = (roomsList || []).filter((item) => {
                  return (
                    item.type !== null &&
                    item.floor !== null &&
                    item.dimension !== null &&
                    item.flooring !== null
                  );
                });
                const data = {
                  ...rest,
                  roomsList: roomsArr,
                };
                console.log(data);

                createUnitRequest(Number(propertyId), data)
                  .then((res) => {
                    // navigate(`/properties/${propertyId}/units/${res.data.id}`);
                  })
                  .catch((err) => setErrors(getAxiosErrors(err)))
                  .finally();
              }}
            >
              {({
                values,
                errors,
                handleBlur,
                touched,
                setFieldValue,
                handleChange,
                isSubmitting,
                dirty,
              }) => (
                <Form className={`${baseClass}--rent-form`}>
                  <div className={`${baseClass}--rent-form-prices`}>
                    <div
                      className={`fw-600 fs-14 ${baseClass}--input-label ${baseClass}--rent-form-prices-item`}
                    >
                      The rent
                    </div>
                    <Input
                      className={`${baseClass}--rent-form-prices-item`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type={'number'}
                      placeholder={'$'}
                      value={values.rentPrice}
                      name={'rentPrice'}
                      errors={
                        errors.rentPrice && touched.rentPrice
                          ? errors.rentPrice
                          : ''
                      }
                    />
                    <div className={`${baseClass}--rent-form-prices-item`}>
                      <div
                        role="group"
                        aria-labelledby="my-radio-group"
                        className={`${baseClass}--input-label ${baseClass}--rent-form-prices-item group`}
                      >
                        <label
                          className={`fs-14 ${baseClass}--rent-form-prices-item-radio`}
                        >
                          <Field
                            className={`fs-14 ${baseClass}--rent-form-prices-item-radio-check`}
                            type="radio"
                            name={'rentPricePeriod'}
                            value={PaymentPeriod.MONTH}
                          />
                          Per month
                        </label>
                        <label
                          className={`fs-14 ${baseClass}--rent-form-prices-item-radio`}
                        >
                          <Field
                            className={`fs-14 ${baseClass}--rent-form-prices-item-radio-check`}
                            type="radio"
                            name={'rentPricePeriod'}
                            value={PaymentPeriod.WEEK}
                          />
                          Per week
                        </label>
                      </div>
                    </div>
                    <div
                      className={`fw-600 fs-14 ${baseClass}--input-label ${baseClass}--rent-form-prices-item`}
                    >
                      The total cost of services
                    </div>
                    <Input
                      className={`${baseClass}--rent-form-prices-item`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type={'number'}
                      placeholder={'$'}
                      value={values.costOfServices}
                      name={'costOfServices'}
                      errors={
                        errors.costOfServices && touched.costOfServices
                          ? errors.costOfServices
                          : ''
                      }
                    />
                    <div className={`${baseClass}--rent-form-prices-item`}>
                      <div
                        role="group"
                        aria-labelledby="my-radio-group"
                        className={`${baseClass}--input-label ${baseClass}--rent-form-prices-item group`}
                      >
                        <label
                          className={`fs-14 ${baseClass}--rent-form-prices-item-radio`}
                        >
                          <Field
                            className={`fs-14 ${baseClass}--rent-form-prices-item-radio-check`}
                            type="radio"
                            name={'costOfServicesPeriod'}
                            value={PaymentPeriod.MONTH}
                          />
                          Per month
                        </label>
                        <label
                          className={`fs-14 ${baseClass}--rent-form-prices-item-radio`}
                        >
                          <Field
                            className={`fs-14 ${baseClass}--rent-form-prices-item-radio-check`}
                            type="radio"
                            name={'costOfServicesPeriod'}
                            value={PaymentPeriod.WEEK}
                          />
                          Per week
                        </label>
                      </div>
                    </div>
                    <div
                      className={`fw-600 fs-14 ${baseClass}--input-label ${baseClass}--rent-form-prices-item`}
                    >
                      The total rent
                    </div>
                    <Input
                      className={`${baseClass}--rent-form-prices-item`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type={'number'}
                      placeholder={'$'}
                      value={values.totalRentPrice}
                      name={'totalRentPrice'}
                      errors={
                        errors.totalRentPrice && touched.totalRentPrice
                          ? errors.totalRentPrice
                          : ''
                      }
                    />
                    <div className={`${baseClass}--rent-form-prices-item`}>
                      <div
                        role="group"
                        aria-labelledby="my-radio-group"
                        className={`${baseClass}--input-label ${baseClass}--rent-form-prices-item group`}
                      >
                        <label
                          className={`fs-14 ${baseClass}--rent-form-prices-item-radio`}
                        >
                          <Field
                            className={`fs-14 ${baseClass}--rent-form-prices-item-radio-check`}
                            type="radio"
                            name={'totalRentPricePeriod'}
                            value={PaymentPeriod.MONTH}
                          />
                          Per month
                        </label>
                        <label
                          className={`fs-14 ${baseClass}--rent-form-prices-item-radio`}
                        >
                          <Field
                            className={`fs-14 ${baseClass}--rent-form-prices-item-radio-check`}
                            type="radio"
                            name={'totalRentPricePeriod'}
                            value={PaymentPeriod.WEEK}
                          />
                          Per week
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={`${baseClass}--rent-form-pricesDesc`}>
                    <Field
                      className={`${baseClass}--rent-form-pricesDesc-textarea`}
                      as="textarea"
                      placeholder={'Specify'}
                      name={'rentPriceDescription'}
                    />
                  </div>
                  <div className={`${baseClass}--rent-form-periods`}>
                    <div
                      className={`fw-600 fs-14 ${baseClass}--rent-form-periods-title`}
                    >
                      First payment period
                    </div>
                    <div
                      className={`fs-14 ${baseClass}--rent-form-periods-row`}
                    >
                      <span
                        className={`${baseClass}--rent-form-periods-row-title`}
                      >
                        The rent will be paid on
                      </span>
                      <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={'ca'}
                      >
                        <DatePicker
                          // label="Select date"
                          className={`${baseClass}--rent-form-periods-row-date`}
                          value={
                            values.firstPaymentDate
                              ? moment(values.firstPaymentDate)
                              : null
                          } // Преобразование значения
                          onChange={
                            (newValue) =>
                              setFieldValue(
                                'firstPaymentDate',
                                newValue ? newValue.toDate() : null,
                              ) // Преобразование обратно в Date
                          }
                          // slots={{
                          //   textField: (textFieldProps) => (
                          //     <TextField {...textFieldProps} />
                          //   ),
                          // }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div
                      className={`fw-600 fs-14 ${baseClass}--rent-form-periods-title`}
                    >
                      Other payment periods
                    </div>
                    <div
                      className={`fs-14 ${baseClass}--rent-form-periods-row`}
                    >
                      <span
                        className={`${baseClass}--rent-form-periods-row-title`}
                      >
                        The rent will be paid on the 1st day
                      </span>
                      <div
                        role="group"
                        aria-labelledby="my-radio-group"
                        className={`${baseClass}--rent-form-prices-item group`}
                      >
                        <label
                          className={`fs-14 ${baseClass}--rent-form-prices-item-radio`}
                        >
                          <Field
                            className={`fs-14 ${baseClass}--rent-form-prices-item-radio-check`}
                            type="radio"
                            name={'paymentFirstDay'}
                            value={PaymentPeriod.MONTH}
                          />
                          of the month
                        </label>
                        <label
                          className={`fs-14 ${baseClass}--rent-form-prices-item-radio`}
                        >
                          <Field
                            className={`fs-14 ${baseClass}--rent-form-prices-item-radio-check`}
                            type="radio"
                            name={'paymentFirstDay'}
                            value={PaymentPeriod.WEEK}
                          />
                          of the week
                        </label>
                      </div>
                    </div>
                    <div
                      className={`fw-600 fs-14 ${baseClass}--rent-form-periods-title`}
                    >
                      Method of payment
                    </div>
                    <div
                      className={`fs-14 ${baseClass}--rent-form-periods-payment`}
                    >
                      <div
                        className={`fs-14 ${baseClass}--rent-form-periods-row`}
                      >
                        <div
                          role="group"
                          aria-labelledby="my-radio-group"
                          className={`${baseClass}--rent-form-prices-item group`}
                        >
                          <label
                            className={`fs-14 ${baseClass}--rent-form-prices-item-radio`}
                          >
                            <Field
                              className={`fs-14 ${baseClass}--rent-form-prices-item-radio-check`}
                              type="radio"
                              name={'paymentMethod'}
                              value={PaymentMethod.CASH}
                            />
                            Cash
                          </label>
                          <label
                            className={`fs-14 ${baseClass}--rent-form-prices-item-radio`}
                          >
                            <Field
                              className={`fs-14 ${baseClass}--rent-form-prices-item-radio-check`}
                              type="radio"
                              name={'paymentMethod'}
                              value={PaymentMethod.CHEQUE}
                            />
                            Cheque
                          </label>
                          <label
                            className={`fs-14 ${baseClass}--rent-form-prices-item-radio`}
                          >
                            <Field
                              className={`fs-14 ${baseClass}--rent-form-prices-item-radio-check`}
                              type="radio"
                              name={'paymentMethod'}
                              value={PaymentMethod.ELECTRONIC_TRANSFER}
                            />
                            Electronic bank transfer
                          </label>
                        </div>
                      </div>
                      <div
                        className={`fs-14 ${baseClass}--rent-form-periods-row`}
                      >
                        <div
                          role="group"
                          aria-labelledby="my-radio-group"
                          className={`${baseClass}--rent-form-prices-item group`}
                        >
                          <label
                            className={`fs-14 ${baseClass}--input-label ${baseClass}--rent-form-prices-item-radio`}
                          >
                            <Field
                              className={`fs-14  ${baseClass}--rent-form-prices-item-radio-check`}
                              type="radio"
                              name={'paymentMethod'}
                              value={PaymentMethod.OTHER}
                            />
                            Other
                          </label>
                        </div>
                        <Input
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={'paymentMethodOtherSpecify'}
                          value={values.paymentMethodOtherSpecify}
                          placeholder={'Specify'}
                          className={`${baseClass}--input ${baseClass}--rent-form-periods-row-input`}
                          errors={
                            errors.paymentMethodOtherSpecify &&
                            touched.paymentMethodOtherSpecify
                              ? errors.paymentMethodOtherSpecify
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${baseClass}--inclusions-btns`}>
                    <Button type={'submit'}>Save</Button>
                    <Button type={'reset'} theme={'secondary'}>
                      Clear
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Container>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddUnitPage;
