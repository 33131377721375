import React, { FC } from 'react';
import './PropertyOverviewCard.scss';
import Card from '@/components/Card';
import convertAddressToString from '@/utils/convertAddressToString';
import Button from '@/components/Button';
import MapComponent from '@/components/MapComponent';
import { IProperty } from '@/types';
import { useNavigate } from 'react-router-dom';

const baseClass = 'PropertyOverviewCard';

interface IPropertyOverview {
  property: IProperty | null | undefined;
  navButton?: React.ReactNode;
}

const PropertyOverviewCard: FC<IPropertyOverview> = ({
  property,
  navButton,
}) => {
  const navigate = useNavigate();
  return (
    <Card className={baseClass}>
      <Card.Header className={`${baseClass}-header`}>
        <Card.Title className={`${baseClass}-title`}>
          <span>Property overview</span>
        </Card.Title>
      </Card.Header>
      <Card.Body className={`${baseClass}-body`}>
        <Card.Container className={`${baseClass}-container`}>
          <div className={`${baseClass}-item`}>
            <div className={`${baseClass}-item-title`}>{property?.name}</div>
            <div className={`${baseClass}-item-type residential`}>
              residential
            </div>
            <div className={`${baseClass}-item-desc`}>
              <span className={`${baseClass}-item-cell fw-600 fs-14 `}>
                Address:
              </span>
              <span className={`${baseClass}-item-cell fs-14`}>
                {convertAddressToString(property?.address)}
              </span>
              <span className={`${baseClass}-item-cell fw-600 fs-14 `}>
                Units:
              </span>
              <span className={`${baseClass}-item-cell fs-14`}>
                {property?.address?.units?.length || 0}
              </span>
            </div>
            <div className={`${baseClass}-item-btn`}>
              {navButton || (
                <Button
                  // className={`${baseClass}-item-btn`}
                  theme={'primary'}
                  onClick={() => {
                    navigate('/properties/' + property?.id);
                  }}
                >
                  Property information
                </Button>
              )}
            </div>
          </div>
          <MapComponent
            geoData={{
              y: property?.address.latitude || 0,
              x: property?.address.longitude || 0,
              raw: {
                addresstype: 'building' as const,
                type: 'house' as const,
              },
              label: property?.name || '',
            }}
            className={`${baseClass}-item ${baseClass}-map`}
          />
        </Card.Container>
      </Card.Body>
    </Card>
  );
};

export default PropertyOverviewCard;
