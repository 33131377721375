import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { ILogoutResponse } from '@/types';
import { logoutRequest } from '@/api';
import {
  fetchingLogout,
  fetchingLogoutSuccess,
  fetchingSigninError,
} from '@/store/auth/authSlices';
import handleRequestErrors from '@/utils/handleRequestErrors';

function* logoutSaga() {
  const request = async () => logoutRequest();
  try {
    const res: AxiosResponse<ILogoutResponse> = yield call(request);
    console.debug(res.data);
    window.location.reload();
    // Сохранение токенов в Redux
    yield put(fetchingLogoutSuccess());
  } catch (error) {
    yield put(handleRequestErrors(error, fetchingSigninError));
    // if (error.response) {
    //   // Ошибка от сервера
    //   yield put(
    //     fetchingLogoutError(error.response?.data?.message || 'Logging failed'),
    //   );
    // } else {
    //   // Ошибка запроса
    //   yield put(fetchingLogoutError('An error occurred'));
    // }
  }
}

function* watchLogoutSaga() {
  yield takeLatest(fetchingLogout, logoutSaga);
}

export default watchLogoutSaga;
