import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchAllPropertiesResponse, IProperty } from '@/types';

interface PropertiesState {
  tenantProperties: IProperty[];
  ownedProperties: IProperty[];
  loading: boolean;
  error: string | null;
  serverErrors: { [key: string]: string[] } | null;
}

const initialState: PropertiesState = {
  tenantProperties: [],
  ownedProperties: [],
  loading: false,
  error: null,
  serverErrors: null,
};

const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    fetchingProperties(state) {
      state.loading = true;
      state.error = null;
    },
    fetchingPropertiesSuccess(
      state,
      action: PayloadAction<FetchAllPropertiesResponse>,
    ) {
      state.tenantProperties = action.payload.tenantProperties;
      state.ownedProperties = action.payload.ownedProperties;
      state.loading = false;
    },
    fetchingPropertiesError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchingProperties,
  fetchingPropertiesSuccess,
  fetchingPropertiesError,
} = propertiesSlice.actions;

export const tenantPropertiesSelector = (state: PropSavyRootState) =>
  state.properties.tenantProperties;

export const ownedPropertiesSelector = (state: PropSavyRootState) =>
  state.properties.ownedProperties;

export const propertiesLoadingSelector = (state: PropSavyRootState) =>
  state.properties.loading;

export default propertiesSlice.reducer;
