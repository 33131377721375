import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser, ProfileState } from '@/types';

const initialState: ProfileState = {
  profile: null,
  loading: false,
  error: null,
  serverErrors: null,
};

const profileSlices = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    fetchProfileSuccess: (state, action: PayloadAction<IUser>) => {
      state.profile = action.payload;
      state.loading = false;
    },
    fetchProfileFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchingProfile: (state) => {
      state.loading = true;
    },
    updatingProfile: (state, action: PayloadAction<IUser>) => {
      state.loading = true;
    },
    updateProfileSuccess: (state, action: PayloadAction<IUser>) => {
      state.profile = action.payload;
      state.loading = false;
    },
    updateProfileFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const profileSelector = (state: { profile: ProfileState }) =>
  state.profile.profile;

export const profileLoadingSelector = (state: { profile: ProfileState }) =>
  state.profile.loading;

export const { fetchProfileSuccess, fetchProfileFailure, fetchingProfile } =
  profileSlices.actions;

export default profileSlices.reducer;
