import React, { FC } from 'react';
import clsx from 'clsx';
import './Card.scss';

const baseClass = 'Card';

interface CardProps {
  children?: React.ReactNode;
  className?: string;
}

interface CardComponent extends FC<CardProps> {
  Title: FC<CardProps>;
  TitleDescription: FC<CardProps>;
  Header: FC<CardProps>;
  Body: FC<CardProps>;
  Container: FC<CardProps>;
}

// eslint-disable-next-line react/prop-types
const Card: CardComponent = ({ children, className }) => {
  return <div className={clsx(baseClass, className)}>{children}</div>;
};

const Title: FC<CardProps> = ({ children, className }) => {
  return (
    <div className={clsx(`${baseClass}-title`, className)}>{children}</div>
  );
};

const TitleDescription: FC<CardProps> = ({ children, className }) => {
  return (
    <div className={clsx(`${baseClass}-title-description`, className)}>
      {children}
    </div>
  );
};

const Header: FC<CardProps> = ({ children, className }) => {
  return (
    <div className={clsx(`${baseClass}-header`, className)}>{children}</div>
  );
};

const Body: FC<CardProps> = ({ children, className }) => {
  return <div className={clsx(`${baseClass}-body`, className)}>{children}</div>;
};

const Container: FC<CardProps> = ({ children, className }) => {
  return (
    <div className={clsx(`${baseClass}-container`, className)}>{children}</div>
  );
};

Card.Title = Title;
Card.TitleDescription = TitleDescription;
Card.Header = Header;
Card.Body = Body;
Card.Container = Container;

export default Card;
