import React, { FC } from 'react';
import { IProperty } from '@/types';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@/components/Button';
import './PropertyTable.scss';
import convertAddressToString from '@/utils/convertAddressToString';
import { useNavigate } from 'react-router-dom';

const baseClass = 'PropertyTable';

// Компонент для всей таблицы
const PropertyTable: FC<{ properties: IProperty[]; isLoading: boolean }> = ({
  properties,
  isLoading = false,
}) => {
  const navigate = useNavigate();
  return (
    <TableContainer component={Paper} elevation={0} className={baseClass}>
      <Table sx={{ minWidth: '300px' }} aria-label="simple table">
        <TableHead className={`${baseClass}--head`}>
          <TableRow className={`${baseClass}--head-tr`}>
            <TableCell className={`${baseClass}--head-th`}>
              <div className={`${baseClass}--head-th-content`}>
                <div className={`${baseClass}--head-th-label`}>PROPERTY</div>
                <div className={`${baseClass}--head-th-btns`}>
                  <i
                    className={`fa-solid fa-sort-up ${baseClass}--head-th-btns-icon active`}
                  ></i>
                  <i
                    className={`fa-solid fa-sort-down  ${baseClass}--head-th-btns-icon`}
                  ></i>
                </div>
              </div>
            </TableCell>
            <TableCell align="left" className={`${baseClass}--head-th`}>
              <div className={`${baseClass}--head-th-label`}>
                <div className={`${baseClass}--head-th-content`}>
                  <div className={`${baseClass}--head-th-label`}>ADDRESS</div>
                  <div className={`${baseClass}--head-th-btns`}>
                    <i
                      className={`fa-solid fa-sort-up ${baseClass}--head-th-btns-icon `}
                    ></i>
                    <i
                      className={`fa-solid fa-sort-down  ${baseClass}--head-th-btns-icon`}
                    ></i>
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell align="left" className={`${baseClass}--head-th`}>
              <div className={`${baseClass}--head-th-label`}>
                <div className={`${baseClass}--head-th-content`}>
                  <div className={`${baseClass}--head-th-label`}>UNITS</div>
                  <div className={`${baseClass}--head-th-btns`}>
                    <i
                      className={`fa-solid fa-sort-up ${baseClass}--head-th-btns-icon `}
                    ></i>
                    <i
                      className={`fa-solid fa-sort-down  ${baseClass}--head-th-btns-icon`}
                    ></i>
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell align="left" className={`${baseClass}--head-th`}>
              <div className={`${baseClass}--head-th-label`}>
                <div className={`${baseClass}--head-th-content`}>
                  <div className={`${baseClass}--head-th-label`}>LEASED</div>
                  <div className={`${baseClass}--head-th-btns`}>
                    <i
                      className={`fa-solid fa-sort-up ${baseClass}--head-th-btns-icon `}
                    ></i>
                    <i
                      className={`fa-solid fa-sort-down  ${baseClass}--head-th-btns-icon`}
                    ></i>
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell align="left" className={`${baseClass}--head-th`}>
              <div className={`${baseClass}--head-th-label`}>
                <div className={`${baseClass}--head-th-content`}>
                  <div className={`${baseClass}--head-th-label`}>VACANT</div>
                  <div className={`${baseClass}--head-th-btns`}>
                    <i
                      className={`fa-solid fa-sort-up ${baseClass}--head-th-btns-icon`}
                    ></i>
                    <i
                      className={`fa-solid fa-sort-down  ${baseClass}--head-th-btns-icon`}
                    ></i>
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell
              align="center"
              className={`${baseClass}--head-th`}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[
            ...(properties || []),
            // {
            //   number: '1test',
            //   rentPrice: 100,
            //   payment: 'paid',
            //   tenant: {
            //     avatar:
            //       'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Cat03.jpg/1280px-Cat03.jpg',
            //     email: 'test@email.tets',
            //     firstname: 'Foo',
            //     lastname: 'Barrr',
            //   },
            // },
          ].map((row) => (
            <TableRow
              className={`${baseClass}--row`}
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={() => {
                console.debug(row);
              }}
            >
              <TableCell scope="row" className={`${baseClass}--row-cell`}>
                <div className={`${baseClass}--row-cell-name`}>
                  <div className={`${baseClass}--row-cell-name-label`}>
                    {row.name}
                  </div>
                  <div
                    className={`${baseClass}--row-cell-name-type residential`}
                  >
                    residential
                  </div>
                </div>
              </TableCell>
              <TableCell align="left" className={`${baseClass}--row-cell`}>
                <div className={`${baseClass}--row-cell-address`}>
                  {convertAddressToString(row.address)}
                </div>
              </TableCell>
              <TableCell align="left" className={`${baseClass}--row-cell`}>
                {row.address?.unitsCount || 0}
              </TableCell>
              <TableCell align="left" className={`${baseClass}--row-cell`}>
                {row.address?.leasedCount || 0}
              </TableCell>
              <TableCell align="left" className={`${baseClass}--row-cell`}>
                {row.address?.vacantCount || 0}
              </TableCell>
              <TableCell align="left" className={`${baseClass}--row-cell`}>
                <div className={`${baseClass}--unit`}>
                  <Button
                    className={`${baseClass}--unit-btn info`}
                    onClick={() => {
                      navigate(`/properties/${row.id}`);
                    }}
                  >
                    <i className="fa-solid fa-circle-info"></i>
                  </Button>
                  <Button
                    className={`${baseClass}--unit-btn`}
                    onClick={() => {
                      navigate(`/properties/${row.id}/edit`);
                    }}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PropertyTable;
