export const isDev = process.env.NODE_ENV === 'development';

export type ProfileState = {
  profile: IUser | null;
  loading: boolean;
  error: null | string;
  serverErrors: { [key: string]: string[] } | null;
};

export type SignupDto = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  inviteToken?: string | null;
};

export type SigninDto = {
  email: string;
  password: string;
  inviteToken?: string | null;
};

// export type IAddress = {
//   id: number;
//   country: string;
//   stateOrProvince?: string | null;
//   city?: string | null;
//   street?: string | null;
//   houseNumber?: string | null;
//   apartmentNumber?: string | null;
//   postalCode?: string | null;
//   latitude?: string | null;
//   longitude?: string | null;
//   phone?: string | null;
//   createdAt: Date;
//   updatedAt: Date;
// };

export enum PaymentPeriod {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export enum PaymentStatus {
  NO_DATA = 'NO_DATA',
  DEBT = 'DEBT',
  PAID = 'PAID',
}

export enum PaymentMethod {
  OTHER = 'OTHER',
  CASH = 'CASH',
  CHEQUE = 'CHEQUE',
  ELECTRONIC_TRANSFER = 'ELECTRONIC_TRANSFER',
}

export type IUnit = {
  id?: number;
  number: string;
  tenant?: IUser | null; // TODO change to IUser[]
  payment?: PaymentStatus | null;
  address?: IAddress | null;
  floor?: string | null;
  rooms?: number | null;
  dimension?: number | null;
  bedrooms?: number | null;
  numberOfFloors?: string | null;
  bathrooms?: number | null;
  roomsList?:
    | {
        type?: string | null;
        floor?: string | null;
        flooring?: string | null;
        dimension?: number | null;
      }[]
    | null;
  informOtherChecked?: boolean | null;
  informOtherSpecify?: string | null;
  outdoorParking?: boolean | null;
  outdoorParkingNumbers?: number | null;
  outdoorParkingSpaces?: string | null;
  indoorParking?: boolean | null;
  indoorParkingNumbers?: number | null;
  indoorParkingSpaces?: string | null;
  lockerOrStorage?: boolean | null;
  lockerOrStorageSpecify?: string | null;
  otherAccessoriesAndDependencies?: boolean | null;
  otherAccessoriesAndDependenciesSpecify?: string | null;
  furnitureIsIncluded?: boolean | null;
  stove?: boolean | null;
  dishwasher?: boolean | null;
  washer?: boolean | null;
  microwave?: boolean | null;
  refrigerator?: boolean | null;
  dryer?: boolean | null;
  isTables?: boolean | null;
  tablesNumber?: number | null;
  isChestOfDrawers?: boolean | null;
  chestOfDrawersNumber?: number | null;
  isChairs?: boolean | null;
  chairsNumber?: number | null;
  isCouches?: boolean | null;
  couchesNumber?: number | null;
  isArmchairs?: boolean | null;
  armchairsNumber?: number | null;
  isBeds?: boolean | null;
  bedsNumber?: number | null;
  bedsSize?: string | null;
  isInclusionsOther?: boolean | null;
  inclusionsOtherSpecify?: string | null;
  inviteEmail?: string | null;
  inviteLink?: string | null;
  documents?: File[] | null;
  isFixedTermsLease?: boolean | null;
  termLeasePeriod?: string | null;
  termLeaseStartDate?: Date | null;
  termLeaseEndDate?: Date | null;
  isIndeterminateTermLease?: boolean | null;
  indeterminateTermLeaseStartDate?: Date | null;
  rentPrice?: number | null;
  rentPricePeriod?: PaymentPeriod | null;
  costOfServices?: number | null;
  costOfServicesPeriod?: PaymentPeriod | null;
  totalRentPrice?: number | null;
  totalRentPricePeriod?: PaymentPeriod | null;
  rentPriceDescription?: string | null;
  firstPaymentDate?: Date | null;
  paymentFirstDay?: PaymentPeriod | null;
  paymentMethod?: PaymentMethod | null;
  paymentMethodOtherSpecify?: string | null;
};

export type IAddress = {
  id?: number;
  country: string;
  stateOrProvince?: string | null;
  postalCode?: string | null;
  city?: string | null;
  street?: string | null;
  buildingNumber?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  phone?: string | null;
  createdAt?: Date | null;
  updatedAt?: Date | null;
  units?: IUnit[];
  unitsCount?: number;
  leasedCount?: number;
  vacantCount?: number;
};

export interface IUser {
  id: number;
  avatar?: string | null;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  // addresses: Partial<IAddress>[];
  addresses: Partial<IAddress> | null;
  userType: 'TEST' | null;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  deleted: boolean;
  password?: string | null;
  confirmationToken?: string | null;
  isEmailConfirmed?: boolean | null;
}

export interface AuthResponse {
  accessToken: string;
  refreshToken: string;
  user: IUser;
  propertyId?: number;
}

export interface ILogoutResponse {
  message: string;
}

export type AuthErrorsResponse = {
  message: string;
  errors?: {
    [key: string]: string[]; // ключ - название поля, значение - массив сообщений об ошибках
  };
  error: string;
  statusCode: number;
};

export enum PropertyType {
  residential = 'residential',
  commercial = 'commercial',
}

export enum PropertyStatus {
  available = 'available',
  rented = 'rented',
}

export interface Invite {
  id: number;
  token: string;
  isUsed: boolean;
  createdAt: Date;
  updatedAt: Date;
}

// export interface IProperty {
//   id: number;
//   owner?: IUser | null;
//   tenant?: IUser | null;
//   invites?: Invite[];
//   name: string;
//   rentPrice: number;
//   type: PropertyType;
//   status: PropertyStatus;
//   address: IAddress;
//   createdAt?: Date;
//   updatedAt?: Date;
// }

export interface IProperty {
  id?: number;
  name: string;
  address: IAddress;
  owner?: IUser | null;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface FetchAllPropertiesResponse {
  tenantProperties: IProperty[];
  ownedProperties: IProperty[];
}

// export interface AddPropertyValues {
//   name: string;
//   rentPrice: number;
//   type: PropertyType;
//   status: PropertyStatus;
//   address: Partial<IAddress>;
// }

export interface AddPropertyValues {
  name: string;
  address: Partial<IAddress>;
  owner?: IUser | null;
}

export interface UpdatePropertyRequest {
  id: number;
  data: AddPropertyValues;
}

export interface SocketResponse<T, E = string> {
  data: T;
  error?: E; // ISocketException
}

export interface WsException {
  status?: 'error' | 'exception';
  message: string;
  name?: string;
}

export interface Message {
  id: number;
  text: string;
  chat?: Chat;
  sender: IUser;
  createdAt: string;
  isRead: boolean;
}

export interface Chat {
  id: number;
  title?: string | null;
  messages: Message[];
  createdAt: string;
  lastMessageAt: string;
  participants: Message[];
  property?: IProperty | null;
}

export interface ChatsResponse extends Chat {
  message?: string | null;
}

export interface SendMessageRequest {
  text: string;
}
