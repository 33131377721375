import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddPropertyValues,
  Invite,
  IProperty,
  IUser,
  UpdatePropertyRequest,
} from '@/types';

interface PropertyState {
  property: IProperty | null;
  loading: boolean;
  error: string | null;
  serverErrors: { [key: string]: string[] } | null;
  isPropertyCreated: boolean;
  isPropertyUpdated: boolean;
  isPropertyDeleted: boolean;
  // owner?: IUser | null;
  // tenant?: IUser | null;
  // invite?: Invite | null;
}

const initialState: PropertyState = {
  property: null,
  loading: false,
  error: null,
  serverErrors: null,
  isPropertyCreated: false,
  isPropertyUpdated: false,
  isPropertyDeleted: false,
  // owner: null,
  // tenant: null,
  // invite: null,
};

const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchingProperty(state, _action: PayloadAction<number>) {
      state.loading = true;
      state.error = null;
    },
    fetchingPropertySuccess(state, action: PayloadAction<IProperty>) {
      state.property = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchingPropertyError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createProperty(state, _action: PayloadAction<AddPropertyValues>) {
      state.loading = true;
      state.error = null;
    },
    createPropertySuccess(state, action) {
      state.property = action.payload;
      state.loading = false;
      state.error = null;
      state.isPropertyCreated = true;
    },
    createPropertyError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.isPropertyCreated = false;
    },
    updatingProperty(state, _action: PayloadAction<UpdatePropertyRequest>) {
      state.loading = true;
      state.error = null;
    },
    updatingPropertySuccess(state, action: PayloadAction<IProperty>) {
      state.property = action.payload;
      state.loading = false;
      state.error = null;
      state.isPropertyUpdated = true;
    },
    updatingPropertyError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.isPropertyUpdated = false;
    },
    deletingProperty(state, _action: PayloadAction<number>) {
      state.loading = true;
      state.error = null;
    },
    deletingPropertySuccess(state) {
      state.property = null;
      state.loading = false;
      state.error = null;
      state.isPropertyDeleted = true;
    },
    deletingPropertyError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.isPropertyDeleted = false;
    },
    setIsPropertyCreated(state, action: PayloadAction<boolean>) {
      state.isPropertyCreated = action.payload;
    },
    setIsPropertyUpdated(state, action: PayloadAction<boolean>) {
      state.isPropertyUpdated = action.payload;
    },
    setIsPropertyDeleted(state, action: PayloadAction<boolean>) {
      state.isPropertyDeleted = action.payload;
    },
    setProperty(state, action: PayloadAction<IProperty | null>) {
      state.property = action.payload;
    },
    // deleteTenant(state) {
    //   state.property!.tenant = null;
    // },
    // addTenant(state, action: PayloadAction<IUser>) {
    //   if (state.property) {
    //     state.property.tenant = action.payload;
    //   }
    // },
    // setInvites(state, action: PayloadAction<Invite[]>) {
    //   if (state.property) {
    //     state.property.invites = action.payload;
    //   }
    // },
    // deleteInvites(state) {
    //   if (state.property) {
    //     state.property.invites = [];
    //   }
    // },
  },
});

export const propertyLoadingSelector = (state: PropSavyRootState) =>
  state.property.loading;

export const propertyErrorSelector = (state: PropSavyRootState) =>
  state.property.error;

export const propertySelector = (state: PropSavyRootState) =>
  state.property.property;

export const isPropertyCreatedSelector = (state: PropSavyRootState) =>
  state.property.isPropertyCreated;

export const isPropertyUpdatedSelector = (state: PropSavyRootState) =>
  state.property.isPropertyUpdated;

export const isPropertyDeletedSelector = (state: PropSavyRootState) =>
  state.property.isPropertyDeleted;

export const {
  fetchingProperty,
  fetchingPropertySuccess,
  fetchingPropertyError,
  createProperty,
  createPropertySuccess,
  createPropertyError,
  updatingProperty,
  updatingPropertySuccess,
  updatingPropertyError,
  setIsPropertyCreated,
  setIsPropertyUpdated,
  setIsPropertyDeleted,
  setProperty,
  deletingProperty,
  deletingPropertySuccess,
  deletingPropertyError,
  // deleteTenant,
  // addTenant,
  // setInvites,
  // deleteInvites,
} = propertySlice.actions;

export default propertySlice.reducer;
